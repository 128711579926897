import React from 'react'
import {
  PageHeader,
  Button,
  Image,
  Row,
  Layout
} from 'antd'

import { ICLogo, ICAccount } from 'src/assets/images'
import { CustomGap } from 'src/components'

const { Content } = Layout

const Navbar = () => {
  return (
    <PageHeader ghost={false} className='navbar'>
      <Row align='middle' className='container'>
        <Content className='logo'>
          <Image src={ICLogo} preview={false} />
          <Content className='list-item'>
            <p>Design</p>
            <p>Course</p>
            <p>Certificates</p>
            <p>Email</p>
          </Content>
        </Content>
        <Row align='middle'>
          <Button className='button premium'>
            Premium
          </Button>
          <CustomGap width='2rem' />
          <Image src={ICAccount} preview={false} />
        </Row>
      </Row>
    </PageHeader>
  )
}

export default Navbar
