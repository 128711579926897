import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Form,
  Modal,
  Button,
  Select,
  Layout,
  Divider
} from 'antd'
import {
  LockOutlined,
  UserOutlined,
  MailOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'

import {
  CustomInput,
  CustomButton,
  CustomFormItem,
  CustomGap
} from 'src/components'

import { ILAuth } from 'src/assets/images'

const { Content } = Layout

const Register = ({ dispatch, loading, notice }) => {
  const [planTypeItems] = useState(['SILVER', 'GOLD', 'FREE'])
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (notice !== null) setVisible(true)
    else setVisible(false)
  }, [notice])

  const onFinish = (forms) => {
    if (forms.planType === undefined) forms.planType = 'FREE'
    dispatch({
      type: 'auth/REGISTER',
      payload: forms
    })
  }

  const onHandleRedirect = () => {
    dispatch({
      type: 'auth/SET_STATE',
      payload: {
        notice: null
      }
    })
  }

  return (
    <Layout className='container-register'>
      <Row align='middle'>
        <Col md={12} span={24} className='content-image'>
          <img src={ILAuth} alt='il-auth' />
        </Col>
        <Col md={12} span={24} className='content-form'>
          <Content className=''>
            <p className='big-title'>
              Registration
            </p>
            <p className='sub-title'>
              Registerasi for more information
            </p>
            <CustomGap height='1rem' />
            <Form
              initialValues={{ layout: null }}
              onFinish={onFinish}
              layout='vertical'
              colon={false}
            >
              <CustomFormItem name='fullName'>
                <CustomInput
                  size='medium'
                  tooltipText='username'
                  placeholder='Full Name'
                  prefix={<UserOutlined className='me-2 text-secondary' />}
                />
              </CustomFormItem>
              <CustomFormItem name='username'>
                <CustomInput
                  size='medium'
                  placeholder='Username'
                  tooltipText='only lowercase, digits and underscore allowed'
                  prefix={<UserOutlined className='me-2 text-secondary' />}
                />
              </CustomFormItem>
              <CustomFormItem name='email'>
                <CustomInput
                  size='medium'
                  placeholder='E-mail'
                  tooltipText='example@gmail.com'
                  prefix={<MailOutlined className='me-2 text-secondary' />}
                />
              </CustomFormItem>
              <CustomFormItem
                name='planType'
                initialValues='FREE'
                size='medium'
                noRequired
                label={
                  <p className='sub-title'>
                    Choice PlanType
                  </p>
                  }
              >
                <Select
                  allowClear
                  size='medium'
                  placeholder='FREE'
                  className='rounded'
                >
                  {
                      planTypeItems.map((item, index) =>
                        <Select.Option
                          key={index}
                          value={item}
                        >
                          {item}
                        </Select.Option>
                      )
                    }
                </Select>
              </CustomFormItem>
              <CustomFormItem name='password'>
                <CustomInput
                  size='medium'
                  password
                  placeholder='Password'
                  prefix={<LockOutlined className='me-2 text-secondary' />}
                />
              </CustomFormItem>
              <CustomFormItem name='confirmPassword'>
                <CustomInput
                  size='medium'
                  password
                  placeholder='Confirm Password'
                  prefix={<LockOutlined className='me-2 text-secondary' />}
                />
              </CustomFormItem>
              <Button
                block
                size='medium'
                type='primary'
                htmlType='submit'
              >
                Register Now
              </Button>
            </Form>
            <Divider>
              <small>I'll have account ? &nbsp;
                <CustomButton
                  onClick={onHandleRedirect}
                  htmlType='submit'
                  type='primary'
                  size='small'
                  to='/login'
                >
                  <small>Sign In</small>
                </CustomButton>
              </small>
            </Divider>
          </Content>
        </Col>
      </Row>
      <Modal
        title={false}
        centered
        visible={visible}
        closable={false}
        footer={false}
        className='notification-messages'
      >
        <Row justify='center'>
          <ExclamationCircleOutlined
            style={{
              fontSize: '4rem',
              color: 'red'
            }}
          />
        </Row>
        <Content className='description'>
          {notice?.map((item, index) =>
            <li key={index}>
              <p className='text-capitalize'>
                <span className='fw-bold'>
                  {index + 1}
                </span>.{item}
              </p>
            </li>
          )}
        </Content>
        <Row justify='center'>
          <Button
            type='primary'
            className='rounded'
            onClick={() => setVisible(!visible)}
          >
            Close
          </Button>
        </Row>
      </Modal>
    </Layout>
  )
}

const mapStateToProps = ({ reducerAuth }) => {
  return { ...reducerAuth }
}

export default connect(mapStateToProps)(Register)

Register.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  notice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
}
