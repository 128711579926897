import React from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Menu,
  Layout
} from 'antd'
import {
  HomeOutlined,
  LockOutlined,
  ToolOutlined,
  FileSyncOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { CustomButton, CustomGap } from 'src/components'
import { ILAvatarUser } from 'src/assets/images'

const LayoutProfile = ({ children }) => {
  const menu = [
    {
      title: 'Edit Profile',
      to: '/profile',
      icon: <HomeOutlined style={{ marginRight: '7px', fontSize: 18 }} />
    },
    {
      title: 'Change Password',
      to: '/profile-password',
      icon: <LockOutlined style={{ marginRight: '7px', fontSize: 18 }} />
    },
    {
      title: 'Signature',
      to: '/profile-signature',
      icon: <FileSyncOutlined style={{ marginRight: '7px', fontSize: 18 }} />
    },
    {
      title: 'Settings',
      to: '/profile/settings',
      icon: <ToolOutlined style={{ marginRight: '7px', fontSize: 18 }} />
    }
  ]

  return (
    <Layout className='container-profile'>
      <Row>
        <Col md={5} className='profile-sidebar'>
          <img
            alt='profile'
            className='icon-profile'
            src={ILAvatarUser}
          />
          <p>
            Diko Mahendra
          </p>
          <CustomGap height='2rem' />
          <Menu className='bg-transparent'>
            {menu.map((item, index) =>
              <Menu.Item key={index} className='menu-item'>
                <Link to={item.to} style={{ textDecoration: 0, color: '#ffff' }}>
                  {item.icon} {item.title}
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </Col>
        <Col md={19} className='profile-content'>
          <Layout className='content-header'>
            <Row align='middle' justify='space-between'>
              <div>
                <p className='breadcrumb'>
                  Profile / Settings
                </p>
                <p className='title'>
                  Profile Settings
                </p>
              </div>
              <CustomButton
                to='/'
                type='primary'
                className='rounded-pill'
              >
                <HomeOutlined /> Home
              </CustomButton>
            </Row>
          </Layout>
          {children}
        </Col>
      </Row>
    </Layout>
  )
}

export default LayoutProfile

LayoutProfile.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}
