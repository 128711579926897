/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SaveOutlined } from '@ant-design/icons'
import {
  Row,
  Col,
  Layout,
  Button,
  PageHeader,
  Input
} from 'antd'

import { CustomGap } from 'src/components'

const { Content } = Layout

const TopBar = ({
  loading,
  dispatch,
  orientation,
  category,
  format,
  canvas
}) => {
  const [name, setName] = React.useState('')
  const token = localStorage.getItem('isAuthorized')

  function dataURLtoBlob (dataurl) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  const onHandleSaveCertificate = () => {
    const image = canvas.toDataURL('image/png')
    const blob = dataURLtoBlob(image)

    const fileToJson = new Blob([JSON.stringify(canvas)], {
      type: 'application/json'
    })

    dispatch({
      type: 'certificate/save',
      payload: {
        token,
        params: {
          name: name,
          format: format,
          image: blob,
          orientation: orientation.toUpperCase(),
          category: category,
          body: fileToJson,
          height: canvas.height,
          width: canvas.width
        }
      }
    })
  }

  return (
    <Content>
      <PageHeader className='container top-bar' ghost={false}>
        <Row justify='space-between' align='middle'>
          <p className='big-title'>
            Design Certificate
          </p>
          <Col md={8} span={24} className='action-save'>
            <Input
              onChange={({ target }) => setName(target.value)}
              placeholder='Your project name'
            />
            <CustomGap width='.5rem' />
            <Button
              loading={loading}
              disabled={name === ''}
              onClick={() => onHandleSaveCertificate()}
            >
              <SaveOutlined />
            </Button>
          </Col>
        </Row>
      </PageHeader>

    </Content>
  )
}

const mapStateProps = ({ reducerCertificate }) => {
  return { ...reducerCertificate }
}

export default connect(mapStateProps)(TopBar)

TopBar.propTypes = {
  canvas: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  dispatch: PropTypes.func,
  orientation: PropTypes.string,
  format: PropTypes.string,
  category: PropTypes.string,
  loading: PropTypes.bool
}
