import React from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Image,
  Layout,
  Button
} from 'antd'

import { IL404 } from 'src/assets/images'

const { Content } = Layout

const NotFound = () => {
  return (
    <Layout className='container-general'>
      <Content className='content-center'>
        <Content>
          <Image src={IL404} preview={false} />
          <h1 className='big-title'>
            Ups... Page Not Found
          </h1>
          <Row justify='center'>
            <p className='description'>
              Uh oh, we can’t seem to find the page
              you’re looking for, Try going back to the
              previous page or see our Help Center for more information
            </p>
          </Row>
          <Row justify='center'>
            <Link to='/'>
              <Button className='button home'>
                Home
              </Button>
            </Link>.
          </Row>
        </Content>
      </Content>
    </Layout>
  )
}

export default NotFound
