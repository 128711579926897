import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { compose, createStore, applyMiddleware } from 'redux'
import { createHashHistory } from 'history'
import { CookiesProvider } from 'react-cookie'
import reducers from 'src/redux/reducers'
import sagas from 'src/redux/sagas'

import Routers from './Router'

import 'antd/dist/antd.css'
import 'src/assets/styles/framework/boostrap/bootstrap.css'
import './assets/styles/global.scss'
require('dotenv').config()

const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development' && true) {
  middlewares.push(logger)
}

const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <Routers history={history} />
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
)

export { store, history }
