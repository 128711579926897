import { actions } from './actions'

const initalState = {
  loading: false,
  isUser: '',
  notice: null,
  token: null
}

const reducerAuth = (state = initalState, action) => {
  switch (action.type) {
    case actions.set_state:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export { reducerAuth }
