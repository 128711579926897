import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Layout, Steps } from 'antd'
import { connect } from 'react-redux'
import {
  ProfileTwoTone,
  IdcardTwoTone,
  CreditCardTwoTone,
  PrinterTwoTone
} from '@ant-design/icons'

import {
  StepEvent,
  StepCommittees,
  StepSession,
  StepModeratorSpeaker,
  StepSelectCertificates,
  CustomFinished
} from 'src/components'
import CustomLayout from 'src/layout/dashboard'

const { Step } = Steps
const { Content } = Layout

const ContainerSteps = ({ step, token, member }) => {
  switch (step) {
    case 0:
      return <StepEvent token={token} />
    case 1:
      return <StepCommittees member={member} token={token} />
    case 2:
      return <StepSession token={token} />
    case 3:
      return <StepModeratorSpeaker token={token} />
    case 4:
      return <StepSelectCertificates token={token} />
    case 5:
      return <CustomFinished token={token} />
    default:
      return <StepEvent token={token} />
  }
}

const CreateEvent = ({ dispatch, step = 0, token, member }) => {
  useEffect(() => {
    dispatch({
      type: 'event/get_member',
      payload: { token }
    })
  }, [])

  return (
    <CustomLayout>
      <Layout className='container-create-event'>
        <Content className='container-step'>
          <Steps>
            <Step
              title='General Information'
              icon={<ProfileTwoTone />}
            />
            <Step
              title='Committe Information'
              icon={<IdcardTwoTone />}
            />
            <Step
              title='Session Information'
              icon={<CreditCardTwoTone />}
            />
            <Step
              title='Moderator & Speaker'
              icon={<CreditCardTwoTone />}
            />
            <Step
              title='Choose Design'
              icon={<PrinterTwoTone />}
            />
            <Step
              title='Done'
              icon={<PrinterTwoTone />}
            />
          </Steps>
        </Content>
        <Content>
          <ContainerSteps
            step={step}
            token={token}
            member={member}
          />
        </Content>
      </Layout>
    </CustomLayout>
  )
}

CreateEvent.propTypes = {
  dispatch: PropTypes.func,
  step: PropTypes.number,
  token: PropTypes.string,
  member: PropTypes.object
}

ContainerSteps.propTypes = {
  step: PropTypes.number,
  token: PropTypes.string,
  member: PropTypes.object
}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(CreateEvent)
