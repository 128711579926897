import axios from 'axios'
import service from 'src/services/helper/service'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const moderatorService = {
  get: (token, { eventId }) =>
    service.get(`/api/v1/events/${eventId}/moderators`, token),
  post: (token, { data, eventId }) =>
    service.post(`/api/v1/events/${eventId}/moderators`, data, token),
  patch: (token, { eventId, moderatorId, data }) =>
    service.patch(`/api/v1/events/${eventId}/moderators/${moderatorId}`, data, token),
  delete: (token, { moderatorId, eventId }) =>
    axios.delete(`${apiBaseUrl}/api/v1/events/${eventId}/moderators/${moderatorId}`, { headers: { Authorization: `Bearer ${token}` } })
      .catch(response => response.error)
}

export default moderatorService
