import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Menu,
  Select,
  Button,
  Dropdown,
  PageHeader,
  Modal,
  Input,
  Upload,
  Row,
  Col,
  Avatar,
  Image,
  Divider
} from 'antd'
import {
  CameraOutlined,
  PlusSquareOutlined,
  PlusCircleOutlined,
  SelectOutlined,
  DiffOutlined
} from '@ant-design/icons'
import { rules } from 'src/utils/rules'
import { connect } from 'react-redux'

const CustomHeaderCommitte = ({ dispatch, token, eventId, loading, member }) => {
  const [form] = Form.useForm()
  const [formMember] = Form.useForm()
  const [formCommitte] = Form.useForm()
  const [visible, setVisible] = useState({
    participant: false,
    committe: false,
    selectUser: false,
    member: false
  })

  const menu = (type) => {
    return (
      <Menu>
        <Menu.Item
          key='1'
          icon={<SelectOutlined />}
          onClick={() => onHandleVisible(type, 'select')}
        >
          Select from others
        </Menu.Item>
        <Menu.Item
          key='2'
          icon={<DiffOutlined />}
          onClick={() => onHandleVisible(type, 'create')}
        >
          Create New
        </Menu.Item>
      </Menu>
    )
  }

  const onHandleVisible = (key, type) => {
    switch (key) {
      case 'participant': {
        if (type === 'select') setVisible({ selectUser: true, member: 'participant' })
        if (type === 'create') setVisible({ participant: true })
        return false
      }
      case 'committe': {
        if (type === 'select') setVisible({ selectUser: true, member: 'committe' })
        if (type === 'create') setVisible({ committe: true })
        return false
      }
      default:
        return false
    }
  }

  const onHandleSubmit = (type) => {
    switch (type) {
      case 'participant':
        return form.validateFields()
          .then((data) => {
            dispatch({
              type: 'event/create_participant',
              payload: {
                data,
                token,
                eventId
              }
            })
          })
      case 'committe':
        return formCommitte.validateFields()
          .then((data) => {
            dispatch({
              type: 'event/create_committe',
              payload: {
                data,
                token,
                eventId
              }
            })
          })
      case 'member-participant':
        return formMember.validateFields()
          .then(data => {
            dispatch({
              type: 'event/add_member_participant',
              payload: {
                data,
                token,
                eventId
              }
            })
          })
      case 'member-committe':
        return formMember.validateFields()
          .then(data => {
            dispatch({
              type: 'event/add_member',
              payload: {
                data,
                token,
                eventId
              }
            })
          })

      default:
        return false
    }
  }

  const onHandleClose = (payload) => {
    setVisible(payload)
    form.resetFields()
    formCommitte.resetFields()
    formMember.resetFields()
  }
  const committe = visible.member === 'committe'
  const participant = visible.member === 'participant'
  const formSubmit = committe
    ? () => onHandleSubmit('member-committe')
    : participant ? () => onHandleSubmit('member-participant') : null
  const titleButton = committe ? 'Committe' : 'Participant'

  return (
    <>
      <PageHeader
        ghost={false}
        extra={[
          <Dropdown
            key='1'
            overlay={menu('participant')}
            trigger={['click']}
          >
            <Button
              key='2'
              type='primary'
              icon={<PlusCircleOutlined />}
            >
              Create Participant
            </Button>
          </Dropdown>,
          <Dropdown
            key='3'
            overlay={menu('committe')}
            trigger={['click']}
          >
            <Button
              key='4'
              type='primary'
              icon={<PlusCircleOutlined />}
            >
              Create Committe
            </Button>
          </Dropdown>
        ]}
      />
      {/* Modal Create Participant */}
      <Modal
        visible={visible.participant}
        title='Create Participant'
        footer={false}
        onCancel={() => onHandleClose(!visible)}
      >
        <Form
          form={form}
          requiredMark={false}
          layout='vertical'
          onFinish={() => onHandleSubmit('participant')}
        >
          <Form.Item
            name='avatar'
            label='Upload Avatar'
            rules={[rules.required]}
          >
            <Upload listType='picture-card'>
              <CameraOutlined style={{ fontSize: 20 }} />
            </Upload>
          </Form.Item>
          <Row gutter={8}>
            <Col span={24} md={12}>
              <Form.Item
                name='name'
                label='Name'
                rules={[rules.required]}
              >
                <Input size='large' placeholder='example_user' />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name='email'
                label='Email'
                rules={[rules.required, rules.email]}
              >
                <Input size='large' placeholder='example@email.com' />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name='type'
            label='Choose Type'
            rules={[rules.required]}
          >
            <Select
              size='large'
              placeholder='Host or Co-Host'
              optionFilterProp='children'
            >
              <Select.Option value='HOST'>
                Host
              </Select.Option>
              <Select.Option value='CO_HOST'>
                Co-Host
              </Select.Option>
            </Select>
          </Form.Item>
          <Row justify='end'>
            <Button
              loading={loading}
              type='primary'
              htmlType='submit'
              icon={<PlusSquareOutlined />}
            >
              Create Participant
            </Button>
          </Row>
        </Form>
      </Modal>

      {/* Modal Select from others */}
      <Modal
        title='Select User'
        footer={false}
        visible={visible.selectUser}
        onCancel={() => onHandleClose({ selectUser: false })}
      >
        <Form
          layout='vertical'
          form={formMember}
          onFinish={formSubmit}
        >
          <Form.Item
            name='username'
            label='Choose User'
            rules={[rules.required]}
          >
            <Select
              defaultValue=''
              placeholder='Select a person'
              optionFilterProp='children'
              size='large'
            >
              {member?.data?.items
                ?.map((item, index) =>
                  <Select.Option
                    key={index}
                    value={item?.username}
                  >
                    <Avatar
                      size={30}
                      icon={
                        <Image
                          preview={false}
                          src={item?.avatar}
                        />
                      }
                    />
                    <Divider type='vertical' />
                    {item?.fullName}
                  </Select.Option>
                )}
            </Select>
          </Form.Item>
          {committe
            ? (
              <>
                <Form.Item
                  name='position'
                  label='Position'
                  rules={[rules.required]}
                >
                  <Input placeholder='committe position' size='large' />
                </Form.Item>
                <Form.Item
                  name='company'
                  label='Company Name'
                  rules={[rules.required]}
                >
                  <Input.TextArea placeholder='write your description' size='large' />
                </Form.Item>
              </>
              )
            : (
              <Form.Item
                name='type'
                label='Choose Type'
                rules={[rules.required]}
              >
                <Select
                  size='large'
                  placeholder='Host or Co-Host'
                  optionFilterProp='children'
                >
                  <Select.Option value='HOST'>
                    Host
                  </Select.Option>
                  <Select.Option value='CO_HOST'>
                    Co-Host
                  </Select.Option>
                </Select>
              </Form.Item>)}
          <Row justify='end'>
            <Button
              icon={<PlusCircleOutlined />}
              type='primary'
              loading={loading}
              htmlType='submit'
            >
              Create {titleButton}
            </Button>
          </Row>
        </Form>
      </Modal>

      {/* Modal Create Committe */}
      <Modal
        title='Create Committe'
        footer={false}
        visible={visible.committe}
        onCancel={() => onHandleClose({ committe: false })}
      >
        <Form
          form={formCommitte}
          requiredMark={false}
          layout='vertical'
          onFinish={() => onHandleSubmit('committe')}
        >
          <Form.Item
            name='name'
            label='Name'
            rules={[rules.required]}
          >
            <Input size='large' placeholder='commite name' />
          </Form.Item>
          <Form.Item
            name='email'
            label='Email'
            rules={[rules.required, rules.email]}
          >
            <Input size='large' placeholder='ocmmite@email.com' />
          </Form.Item>
          <Form.Item
            label='No Whatsapp'
            name='phone'
            rules={[rules.required]}
          >
            <Input
              addonBefore='+62'
              size='large'
              style={{ width: '100%' }}
              placeholder='0820912893'
            />
          </Form.Item>
          <Form.Item
            name='position'
            label='Position'
            rules={[rules.required]}
          >
            <Input size='large' placeholder='position' />
          </Form.Item>
          <Form.Item
            name='company'
            label='Company'
            rules={[rules.required]}
          >
            <Input size='large' placeholder='company' />
          </Form.Item>
          <Row justify='end'>
            <Button
              type='primary'
              htmlType='submit'
              icon={<PlusCircleOutlined />}
              loading={loading}
            >
              Create
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(CustomHeaderCommitte)

CustomHeaderCommitte.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  eventId: PropTypes.string,
  loading: PropTypes.bool,
  member: PropTypes.object
}
