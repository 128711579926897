import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CameraOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  Form,
  Modal,
  Upload,
  Select,
  Button,
  Input,
  Col,
  Row,
  Divider
} from 'antd'
import { connect } from 'react-redux'
import { rules } from 'src/utils/rules'
import moment from 'moment'
import confirm from 'antd/lib/modal/confirm'

const Text = ({ children }) => <p style={{ margin: 0 }}>{children}</p>
const { Option } = Select

const ModalUpdateSpeaker = ({
  list,
  token,
  update,
  eventId,
  loading,
  dispatch
}) => {
  const [form] = Form.useForm()
  const [newForm, setNewForm] = useState()

  useEffect(() => {
    setNewForm(update.speaker)
  }, [update.speaker])

  const onHandleSubmit = () => {
    const data = {
      ...newForm,
      sessionIds: newForm?.sessions?.map(item => item.id)
    }

    dispatch({
      type: 'event/update_speaker',
      payload: {
        token,
        eventId,
        data,
        speakerId: data.id
      }
    })
  }

  const onDeselect = (value) => {
    const data = newForm.sessions.filter(item => item.id !== value)
    confirm({
      title: 'Are you sure delete this session?',
      content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk () {
        setNewForm({
          ...newForm,
          sessions: data
        })
      },
      onCancel () {}
    })
  }

  const onHandleSelect = (value, option) => {
    setNewForm({
      ...newForm,
      sessions: [
        ...newForm.sessions,
        {
          id: option.value,
          name: option.label
        }
      ]
    })
  }

  const onHandleVisible = () => {
    dispatch({
      type: 'event/set_state',
      payload: {
        update: {
          visibleSpeaker: false
        }
      }
    })
  }

  return (
    <Modal
      title='Update Speaker'
      visible={update?.visibleSpeaker}
      width={800}
      footer={false}
      onCancel={onHandleVisible}
    >
      <Form layout='vertical' form={form}>
        <Row>
          <Col span={24} md={12}>
            <Form.Item
              label='Avatar'
              rules={[rules.required]}
            >
              <Upload listType='picture-card'>
                <CameraOutlined style={{ fontSize: 24 }} />
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label='Name'
              rules={[rules.required]}
            >
              <Input
                size='large'
                value={newForm?.name}
                onChange={({ target }) => setNewForm({
                  ...newForm,
                  name: target.value
                })}
              />
            </Form.Item>
            <Form.Item
              label='Topic'
              rules={[rules.required]}
            >
              <Input
                size='large'
                value={newForm?.topic}
                onChange={({ target }) => setNewForm({
                  ...newForm,
                  topic: target.value
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label='Select Session'
          rules={[rules.required]}
        >
          <Select
            mode='multiple'
            size='large'
            optionLabelProp='label'
            value={newForm?.sessions?.map(item => item?.id)}
            onSelect={(value, option) => onHandleSelect(value, option)}
            onDeselect={onDeselect}
          >
            {list?.items?.map((item, index) =>
              <Option
                key={index}
                value={item?.id}
                label={item?.name}
              >
                <Row justify='space-between' align='middle'>
                  <Row align='middle'>
                    <a href='#'>
                      {item?.name}
                    </a>
                  </Row>
                  <Row align='middle'>
                    <Text>
                      {moment(item?.startTime).format('ll')}{' '}
                    </Text>
                    <Divider type='vertical' />
                    <Text>
                      {moment(item?.startTime).format('LT')}
                    </Text>
                    <Divider type='vertical' />
                    <Text>
                      {moment(item?.endTime).format('LT')}
                    </Text>
                  </Row>
                </Row>
              </Option>
            )}
          </Select>
        </Form.Item>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label='No Whatsapp'
              rules={[rules.required]}
            >
              <Input
                addonBefore='+62'
                size='large'
                value={newForm?.phone}
                onChange={({ target }) => setNewForm({
                  ...newForm,
                  phone: target.value
                })}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label='Email' rules={[rules.required, rules.email]}>
              <Input
                size='large'
                value={newForm?.email}
                onChange={({ target }) => setNewForm({
                  ...newForm,
                  email: target.value
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label='Description'>
          <Input.TextArea
            size='large'
            value={newForm?.description}
            onChange={({ target }) => setNewForm({
              ...newForm,
              description: target.value
            })}
          />
        </Form.Item>
        <Row justify='end'>
          <Button
            type='primary'
            loading={loading}
            onClick={onHandleSubmit}
            icon={<PlusCircleOutlined />}
          >
            Update Speaker
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(ModalUpdateSpeaker)

ModalUpdateSpeaker.propTypes = {
  dispatch: PropTypes.func,
  update: PropTypes.object,
  token: PropTypes.string,
  loading: PropTypes.bool,
  eventId: PropTypes.string,
  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
}

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ])
}
