import axios from 'axios'
import service from 'src/services/helper/service'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const serviceSession = {
  patch: ({ id, data, sessionId }, token) =>
    service.patch(`/api/v1/events/${id}/sessions/${sessionId}`, data, token),
  post: (token, { eventId, data }) =>
    service.post(`/api/v1/events/${eventId}/sessions`, data, token),
  get: (token, { eventId }) =>
    service.get(`/api/v1/events/${eventId}/sessions`, token),
  delete: (token, { sessionId, eventId }) =>
    axios.delete(`${apiBaseUrl}/api/v1/events/${eventId}/sessions/${sessionId}`, { headers: { Authorization: `Bearer ${token}` } })
      .catch(response => response.error)
}

export default serviceSession
