import axios from 'axios'

require('dotenv').config()

axios.defaults.withCredentials = false

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const headers = (token, slug) => {
  const header = {
    'Content-Type': slug !== '/api/v1/events' && '/api/v1/plain-certies'
      ? 'application/json'
      : 'multipart/form-data'
  }
  if (token != null) header.Authorization = `Bearer ${token}`
  if (token != null) header['Request-Access'] = token
  return header
}

const service = {
  post: (slug, body, token = null) =>
    axios.post(apiBaseUrl + slug, body, { headers: headers(token, slug) })
      .catch(error => error.response),

  update: (slug, body, token = null) =>
    axios.patch(apiBaseUrl + slug, body, { headers: headers(token) })
      .catch(error => error.response),

  put: (slug, body, token = null) =>
    axios.put(apiBaseUrl + slug, body, { headers: headers(token) })
      .catch(error => error.response),

  delete: (slug, body, token = null) =>
    axios.delete(apiBaseUrl + slug, body, { headers: headers(token) })
      .catch(error => error.response),

  get: (slug, token = null, params) =>
    axios.get(apiBaseUrl + slug, {
      headers: headers(token),
      params
    }),

  patch: (slug, body, token = null) =>
    axios.patch(apiBaseUrl + slug, body, { headers: headers(token) })
      .catch(error => error.response)

}

export default service
