import { all } from 'redux-saga/effects'
import authSaga from './Auth/sagas'
import eventSaga from './Events/sagas'
import certificateSaga from './Certificate/sagas'
import utilsSaga from './Utils/sagas'

export default function * rootSaga () {
  yield all([
    authSaga(),
    utilsSaga(),
    eventSaga(),
    certificateSaga()
  ])
}
