/* eslint-disable no-undef */
import {
  all,
  call,
  put,
  takeEvery
} from 'redux-saga/effects'

import serviceCertificate from 'src/services/api/certificate'

import { actions } from './actions'

// * CERTIFICATES MENU

export function * setMenu ({ payload: { menu } }) {
  yield put({
    type: 'certificate/set_state',
    payload: { menu }
  })
}

export function * setMode ({ payload: { mode } }) {
  yield put({
    type: 'certificate/set_state',
    payload: { mode }
  })
}

export function * setType ({ payload: { type } }) {
  yield put({
    type: 'certificate/set_state',
    payload: { type }
  })
}

// * CERTIFICATES DESIGN

export function * setSave ({ payload }) {
  yield put({
    type: 'certificate/set_state',
    payload: {
      loading: true
    }
  })
  const { params, token } = payload

  const data = new FormData()
  data.append('name', params.name)
  data.append('format', params.format)
  data.append('orientation', params.orientation)
  data.append('image', params.image, 'image.png')
  data.append('category', params.category)
  data.append('body', params.body)
  data.append('height', params.height)
  data.append('width', params.width)

  const response = yield call(serviceCertificate.post, data, token)

  if (response.status === 201) {
    yield put({
      type: 'certificate/get_certificates',
      payload: {
        token
      }
    })
    window.location.href = '/certificates'
  } yield put({
    type: 'certificate/set_state',
    payload: {
      loading: false
    }
  })
}

export function * getCertificates ({ payload }) {
  yield put({
    type: 'certificate/set_state',
    payload: {
      loading: true
    }
  })
  const { name, token } = payload
  const response = yield call(serviceCertificate.get, { name }, token)

  if (response.status === 200) {
    yield put({
      type: 'certificate/set_state',
      payload: {
        loading: false,
        list: response.data.data
      }
    })
  } else {
    yield put({
      type: 'certificate/set_state',
      payload: {
        loading: false
      }
    })
  }
}

export function * listTemplates ({ payload }) {
  const response = yield call(serviceCertificate.get, payload.token)
  if (response.status === 200) {
    yield put({
      type: 'certificate/set_state',
      payload: {
        portrait: response.data[0].portrait,
        landscape: response.data[1].landscape
      }
    })
  }
}

export function * deleteCertificate ({ payload }) {
  const { id, token } = payload
  const response = yield call(serviceCertificate.delete, id, token)
  if (response.status === 202) {
    yield put({
      type: 'certificate/get_certificates',
      payload: {
        token
      }
    })
  }
}

export function * previewCertificate ({ payload }) {
  const { token, items } = payload
  const data = new FormData()
  data.append('body', items.body)
  data.append('width', items.width)
  data.append('height', items.height)

  const response = yield call(serviceCertificate.preview, data, token)
  return response
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.menu, setMenu),
    takeEvery(actions.type, setType),
    takeEvery(actions.mode, setMode),
    takeEvery(actions.save, setSave),
    takeEvery(actions.get_certificates, getCertificates),
    takeEvery(actions.template, listTemplates),
    takeEvery(actions.preview, previewCertificate),
    takeEvery(actions.delete, deleteCertificate)
  ])
}
