const actions = {
  state: 'certificate/set_state',
  menu: 'certificate/menu',
  type: 'certificate/type',
  font: 'ceriticate/font',
  mode: 'certificate/mode',
  save: 'certificate/save',
  get_certificates: 'certificate/get_certificates',
  template: 'certificate/template',
  preview: 'certifactes/detail',
  delete: 'certificate/delete_certificate'
}

export { actions }
