import axios from 'axios'
import service from 'src/services/helper/service'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const participantService = {
  get: (eventId, token) => service.get(`/api/v1/events/${eventId}/hosts`, token),
  post: ({ data, eventId }, token) => service.post(`/api/v1/events/${eventId}/hosts`, data, token),
  put: (token, { eventId, hostId, avatar }) => service.put(`/api/v1/events/${eventId}/hosts/${hostId}`, avatar, token),
  patch: (token, { items, eventId, participantId }) => service.patch(`/api/v1/events/${eventId}/hosts/${participantId}`, items, token),
  delete: (token, { participantId }) =>
    axios.delete(apiBaseUrl + `/api/v1/events/hosts/${participantId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .catch(err => err.response)
}

export default participantService
