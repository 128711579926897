import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Col, Layout, Row, Button } from 'antd'
import {
  FileDoneOutlined,
  ExceptionOutlined,
  AuditOutlined,
  UserOutlined,
  MailOutlined
} from '@ant-design/icons'

import { ICMiniAvatar } from 'src/assets/images'
import { CustomGap, CustomInput } from 'src/components'

import LayoutProfile from 'src/layout/profile'

const { Content } = Layout

const AlignCenter = ({ params, value, icon, disabled = true }) => (
  <Row align='middle'>
    <Col md={6}>
      <Row align='middle'>
        {icon}
        <CustomGap width='5px' />
        <p style={{ margin: 0, fontSize: 12 }}>
          {params}
        </p>
      </Row>
    </Col>
    <Col md={18}>
      <CustomInput
        size='medium'
        value={value}
        placeholder={value}
        disabled={disabled}
      />
    </Col>
  </Row>
)

const Profile = () => {
  const [disabled, setDisabled] = React.useState(true)
  const onHandleDisabled = (params) => {
    setDisabled(!disabled)
  }

  return (
    <LayoutProfile>
      <CustomGap height='2rem' />
      <Row>
        <CustomGap height='2rem' />
        <Layout className='content-component'>
          <Row className='content-profile'>
            <Col md={16} span={24}>
              <Col md={18} className='content-detail-left'>
                <Content className='profile-picture'>
                  <img
                    alt='avatar'
                    className='image-avatar'
                    src={ICMiniAvatar}
                  />
                  <p className=' title'>Web Developer</p>
                  <p className='subtitle'>ID: 70f4ba40-3b0f-4c81</p>
                </Content>
                <CustomGap height='3rem' />
                <AlignCenter
                  disabled={disabled}
                  icon={<UserOutlined />}
                  params='Full Name'
                  value='Diko Mahendra'
                />
                <CustomGap height='1rem' />
                <AlignCenter
                  disabled={disabled}
                  icon={<UserOutlined />}
                  params='Username'
                  value='Diko'
                />
                <CustomGap height='1rem' />
                <AlignCenter
                  disabled={disabled}
                  icon={<MailOutlined />}
                  params='Email'
                  value='cocoCholcolate@mail.com'
                />
                <CustomGap height='1rem' />
                <AlignCenter
                  disabled={disabled}
                  icon={<ExceptionOutlined />}
                  params='Account Type'
                  value='Publisher'
                />
                <CustomGap height='1rem' />
                <AlignCenter
                  disabled={disabled}
                  icon={<FileDoneOutlined />}
                  params='Account Status'
                  value='Aktivated'
                  type='activated'
                />
                <CustomGap height='1rem' />
                <AlignCenter
                  disabled={disabled}
                  icon={<AuditOutlined />}
                  params='Membership'
                  value='Premium'
                  type='premium'
                />
                <CustomGap height='1rem' />
                <Row justify='end'>
                  <Button
                    onClick={onHandleDisabled}
                    className='button'
                    type='primary'
                  >
                    {disabled ? 'Edit' : 'Save'} Profile
                  </Button>
                </Row>
              </Col>
            </Col>
          </Row>
        </Layout>
      </Row>
    </LayoutProfile>
  )
}

const mapStateToProps = (props) => {
  return { ...props }
}
export default connect(mapStateToProps)(Profile)

Profile.propTypes = {}

AlignCenter.propTypes = {
  params: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.object,
  disabled: PropTypes.bool
}
