import axios from 'axios'
import service from 'src/services/helper/service'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const committeService = {
  get: (eventId, token) => service.get(`/api/v1/events/${eventId}/committees`, token),
  post: ({ data, eventId }, token) => service.post(`/api/v1/events/${eventId}/committees`, data, token),
  patch: (token, { eventId, items, committeId }) =>
    service.patch(`/api/v1/events/${eventId}/committees/${committeId}`, items, token),
  delete: (token, { committeId }) =>
    axios.delete(apiBaseUrl + `/api/v1/events/committees/${committeId}`,
      { headers: { Authorization: `Bearer ${token}` } })
      .catch(err => err.response)
}

export default committeService
