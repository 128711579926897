import { actions } from './actions'

const initialState = {
  visible: false
}

const reducerUtils = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_VISIBLE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export { reducerUtils }
