/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { fabric } from 'fabric'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'
import {
  Row,
  Col,
  Menu,
  Radio,
  Image,
  Upload,
  Layout,
  Button,
  Select,
  Divider,
  Popover,
  Input,
  Form
} from 'antd'
import {
  DeleteOutlined,
  UploadOutlined,
  PlusSquareOutlined,
  FontSizeOutlined,
  SolutionOutlined,
  QrcodeOutlined,
  LayoutTwoTone
} from '@ant-design/icons'

import {
  CustomGap,
  OrganizeNavbar,
  OrganizeTopBar
} from 'src/components'
import {
  menu,
  fontSize,
  paperSize,
  iconStyle,
  orientation,
  fontFamily,
  shapeElements
} from 'src/utils/item'
import { ILTemplate01 } from 'src/assets/images'

const { Option } = Select
const { Content } = Layout

const CertificatesDesign = ({
  dispatch,
  loading,
  portrait,
  landscape
}) => {
  const [canvas, setCanvas] = useState('')
  const [visible, setVisible] = useState({
    fillColor: false,
    borderColor: false
  })
  const [colorPicker, setColorPicker] = useState({
    fillColor: '',
    borderColor: ''
  })
  const [paperTypeSize, setPaperTypeSize] = useState('A4')
  const [paperOrientation, setPaperOrientation] = useState('Landscape')
  const [selectedMenuItem, setSelectedMenuItem] = useState('Documents')
  const [textCertificates, setTextCertificates] = useState({
    title: 'CERTIFICATE',
    presentPath: 'THIS IS PRESENT TO',
    recipient: '[recipient.name]',
    event: 'EVENT',
    description: 'DESCRIPTION',
    date: '[certifcate.issued_on]',
    panitia: '[panitia.name]'
  })
  const initCanvas = () => (
    new fabric.Canvas('canvas', {
      backgroundColor: '#ffff',
      height: 595,
      width: 842,
      centeredScaling: true
    })
  )

  useEffect(() => {
    if (paperSize || paperOrientation) setCanvas(initCanvas())
    return null
  }, [])

  const onHandlePaperSize = (e, canvi) => {
    if (e.target.value === 'A4') {
      setPaperTypeSize('A4')
      canvi.setDimensions({ height: 842, width: 595 })
      canvi.renderAll()
    }
    if (e.target.value === 'US Latter') {
      setPaperTypeSize('US Latter')
      canvi.setDimensions({ height: 792, width: 612 })
      canvi.renderAll()
    }
  }

  const onHandlePaperOrientation = (e, canvi) => {
    setPaperOrientation(e.target.value)
    if (e.target.value === 'Portrait') {
      if (paperTypeSize === 'A4') {
        canvi.setDimensions({ height: 842, width: 595 })
        canvi.renderAll()
      }
      if (paperTypeSize === 'US Latter') {
        canvi.setDimensions({ height: 792, width: 612 })
        canvi.renderAll()
      }
    }
    if (e.target.value === 'Landscape') {
      if (paperTypeSize === 'A4') {
        canvi.setDimensions({ height: 595, width: 842 })
        canvi.renderAll()
      }
      if (paperTypeSize === 'US Latter') {
        canvi.setDimensions({ height: 612, width: 792 })
        canvi.renderAll()
      }
    }
  }

  const onHandleFillColor = ({ hex }, canvi) => {
    setColorPicker({ ...colorPicker, fillColor: hex })
    canvi?.getActiveObject()?.set('fill', hex)
    canvi?.renderAll()
  }

  const onHandleBorderColor = ({ hex }, canvi) => {
    setColorPicker({ ...colorPicker, borderColor: hex })
    canvi?.getActiveObject()?.set('stroke', hex)
    canvi?.renderAll()
  }

  const onHandleShapeElement = ({ target }, canvas) => {
    if (target.className === 'shape--rectangle') {
      canvas?.add(new fabric.Rect({
        stroke: 'green',
        strokeWidth: 3,
        fill: '',
        height: 100,
        width: 100
      }))
      canvas.renderAll()
    }
    if (target.className === 'shape--triangle') {
      canvas.add(new fabric.Triangle({
        stroke: 'green',
        strokeWidth: 3,
        fill: '',
        height: 100,
        width: 100
      }))
      canvas.renderAll()
    }
    if (target.className === 'shape--circle') {
      canvas.add(new fabric.Circle({
        radius: 50,
        fill: '',
        stroke: 'green',
        strokeWidth: 3
      }))
      canvas.renderAll()
    }
    if (target.className === 'shape--polygon') {
      canvas.add(new fabric.Polygon([
        { x: 50, y: 0 },
        { x: 90, y: 20 },
        { x: 100, y: 60 },
        { x: 75, y: 100 },
        { x: 28, y: 100 },
        { x: 0, y: 60 },
        { x: 10, y: 20 }
      ], {
        stroke: 'green',
        strokeWidth: 3,
        fill: ''
      }))
    }
    if (target.className === 'shape--star') {
      canvas.add(new fabric.Polygon([
        { x: 50, y: 0 },
        { x: 61, y: 35 },
        { x: 98, y: 35 },
        { x: 68, y: 57 },
        { x: 79, y: 91 },
        { x: 50, y: 70 },
        { x: 21, y: 91 },
        { x: 32, y: 57 },
        { x: 2, y: 35 },
        { x: 39, y: 35 }
      ], {
        stroke: 'green',
        strokeWidth: 3,
        fill: ''
      }))
    }
    if (target.className === 'shape--arrow--left') {
      canvas.add(new fabric.Polygon([
        { x: 40, y: 0 },
        { x: 40, y: 20 },
        { x: 100, y: 20 },
        { x: 100, y: 80 },
        { x: 40, y: 80 },
        { x: 40, y: 100 },
        { x: 0, y: 50 }
      ], {
        stroke: 'green',
        strokeWidth: 3,
        fill: ''
      }))
    }
    if (target.className === 'shape--chevron--right') {
      canvas.add(new fabric.Polygon([
        { x: 100, y: 0 },
        { x: 75, y: 50 },
        { x: 100, y: 100 },
        { x: 25, y: 100 },
        { x: 0, y: 50 },
        { x: 25, y: 0 }
      ], {
        stroke: 'green',
        strokeWidth: 3,
        fill: ''
      })
      )
    }
    if (target.className === 'shape--closed') {
      canvas.add(new fabric.Polygon([
        { x: 20, y: 0 },
        { x: 0, y: 20 },
        { x: 30, y: 50 },
        { x: 0, y: 80 },
        { x: 20, y: 100 },
        { x: 50, y: 70 },
        { x: 80, y: 100 },
        { x: 100, y: 80 },
        { x: 70, y: 50 },
        { x: 100, y: 20 },
        { x: 80, y: 0 },
        { x: 50, y: 30 }
      ], {
        stroke: 'green',
        strokeWidth: 3,
        fill: ''
      })
      )
    }
  }

  const onHandleAddText = canvi => {
    const textInput = new fabric.Textbox('Type your text', {
      left: canvi.width / 2.7,
      top: canvi.height / 15
    })
    canvi.add(textInput)
    canvi.renderAll()
  }

  const onHandleFontFamily = (fontFamily, canvi) => {
    canvi?.getActiveObject()?.set('fontFamily', fontFamily)
    canvi?.renderAll()
  }

  const onHandleFontSize = (fontSize, canvi) => {
    canvi?.getActiveObject()?.set('fontSize', fontSize)
    canvi?.renderAll()
  }

  const onHandleStyleObj = (selected, canvi) => {
    const obj = canvi.getActiveObject()
    if (selected === 0) obj?.set('fontWeight', 'bold')
    if (selected === 1) obj?.set('underline', true)
    if (selected === 2) obj?.set('fontStyle', 'italic')
    if (selected === 3) obj?.set('textAlign', 'left')
    if (selected === 4) obj?.set('textAlign', 'center')
    if (selected === 5) obj?.set('textAlign', 'right')
    if (selected === 6) canvi.remove(obj)
    if (selected === 7) {
      const ctx = fabric.util.object.clone(obj)
      canvi.add(ctx)
      canvi.renderAll()
    }
    canvi.renderAll()
  }

  const onHandleAddBackgroundImage = {
    onChange: ({ file }) => {
      const reader = new FileReader()
      reader.onload = function (e) {
        const data = e.target.result
        fabric.Image.fromURL(data, function (img) {
          canvas.backgroundImage = img
          img.set({
            scaleX: canvas.width / img.width,
            scaleY: canvas.height / img.height
          })
          canvas.renderAll()
        })
      }
      reader.readAsDataURL(file.originFileObj)
    },
    multiple: false,
    showUploadList: false
  }

  const onHandleAddImage = (e, canvi) => {
    const files = e.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      const data = e.target.result
      fabric.Image.fromURL(data, function (myImg) {
        const images = myImg.set({ left: 300, top: 0 })
        myImg.scaleToHeight(300)
        myImg.scaleToWidth(300)
        canvas.add(images)
        return canvas.renderAll()
      })
    }
    reader.readAsDataURL(files)
  }

  const handleAddTextItem = async (type) => {
    switch (type) {
      case 'title': {
        const itemText = new fabric.Textbox(textCertificates.title, {
          top: 80,
          fontSize: 34,
          width: 300,
          textAlign: 'center'
        })
        canvas.add(itemText)
        canvas.setActiveObject(itemText)
        const object = canvas.getActiveObject()
        object.centerH((canvas.getScaleWidth / object.getScaleWidth) / 2)
        return canvas.renderAll()
      }
      case 'presentPath': {
        const itemText = new fabric.Textbox(textCertificates.presentPath, {
          top: 130,
          fontSize: 18,
          width: 350,
          textAlign: 'center'
        })
        canvas.add(itemText)
        canvas.setActiveObject(itemText)
        const object = canvas.getActiveObject()
        object.centerH((canvas.getScaleWidth / object.getScaleWidth) / 2)
        return canvas.renderAll()
      }
      case 'recipient': {
        const itemText = new fabric.Textbox(textCertificates.recipient, {
          top: 230,
          fontSize: 30,
          width: 300,
          textAlign: 'center'
        })
        canvas.add(itemText)
        canvas.setActiveObject(itemText)
        const object = canvas.getActiveObject()
        object.centerH((canvas.getScaleWidth / object.getScaleWidth) / 2)
        return canvas.renderAll()
      }
      case 'event': {
        const itemText = new fabric.Textbox(textCertificates.event, {
          top: 300,
          fontSize: 16,
          width: 300,
          textAlign: 'center'
        })
        canvas.add(itemText)
        canvas.setActiveObject(itemText)
        const object = canvas.getActiveObject()
        object.centerH((canvas.getScaleWidth / object.getScaleWidth) / 2)
        return canvas.renderAll()
      }
      case 'description': {
        const itemText = new fabric.Textbox(textCertificates.description, {
          top: 350,
          fontSize: 20,
          width: 550,
          textAlign: 'center'
        })
        canvas.add(itemText)
        canvas.setActiveObject(itemText)
        const object = canvas.getActiveObject()
        object.centerH((canvas.getScaleWidth / object.getScaleWidth) / 2)
        return canvas.renderAll()
      }
      case 'date': {
        const itemText = new fabric.Textbox(textCertificates.date, {
          top: canvas.height / 1.1,
          fontSize: 16,
          width: 200,
          left: 50,
          textAlign: 'left'
        })
        canvas.add(itemText)
        canvas.setActiveObject(itemText)
        return canvas.renderAll()
      }
      case 'panitia': {
        const itemText = new fabric.Textbox(textCertificates.panitia, {
          top: canvas.height / 1.1,
          left: canvas.width / 1.5,
          fontSize: 16,
          width: 150,
          textAlign: 'right'
        })
        canvas.add(itemText)
        canvas.setActiveObject(itemText)
        return canvas.renderAll()
      }
      default:
        return null
    }
  }

  const onHandleRemoveBackroundImg = canvi => {
    canvi.setBackgroundImage(null)
    canvi.renderAll()
  }

  const onHandleTemplatePortrait = (obj, canvi) => {
    canvi.loadFromJSON(JSON.stringify(obj))
    canvi.renderAll()
  }

  const onHandleTemplateLandscape = (obj, canvi) => {
    canvi.loadFromJSON(JSON.stringify(obj))
    canvi.renderAll()
  }

  const onHandleRectQrCode = (canvi) => {
    canvi.add(new fabric.Rect({
      left: 40,
      top: 40,
      width: 102,
      height: 102,
      strokeWidth: 2,
      stroke: 'QR_CODE',
      hasControls: true,
      lockScalingX: true,
      lockScalingY: true
    }))
    canvi.renderAll()
  }

  const componentsSwtich = (key) => {
    switch (key) {
      case 'Documents':
        return (
          <Layout className='content-setup'>
            <Form layout='horizontal'>
              <Form.Item label='Paper Size :'>
                <Radio.Group
                  defaultValue='A4'
                  onChange={(e) => onHandlePaperSize(e, canvas)}
                >
                  {paperSize.map((item) =>
                    <Radio key={item} value={item}>
                      {item}
                    </Radio>
                  )}
                </Radio.Group>
              </Form.Item>
              <Form.Item label='Orientation :'>
                <Radio.Group
                  defaultValue='Landscape'
                  onChange={(e) => onHandlePaperOrientation(e, canvas)}
                >
                  {orientation.map((item) =>
                    <Radio key={item} value={item}>
                      {item}
                    </Radio>
                  )}
                </Radio.Group>
              </Form.Item>
            </Form>
            <Divider />
            <CustomGap height='1rem' />
            <Content className='content-actions'>
              <Row justify='center'>
                <Upload {...onHandleAddBackgroundImage}>
                  <Button type='primary' icon={<UploadOutlined />}>
                    Upload Background
                  </Button>
                </Upload>
              </Row>
              <p className='notice-description'>
                Minimal size 794px x 1123px
              </p>
              <CustomGap height='1rem' />
              <Row justify='center'>
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => onHandleRemoveBackroundImg(canvas)}
                >
                  Delete Background
                </Button>
              </Row>
            </Content>
          </Layout>
        )
      case 'Templates':
        return (
          <Layout className='content-template'>
            <Content>
              <p className='title-bar'>
                A4-Portrait
              </p>
              <Row gutter={16}>
                {portrait?.map((item, index) =>
                  <Col md={8} key={index} className='mt-3'>
                    <Image
                      preview={false}
                      src={ILTemplate01}
                      alt={index}
                      onClick={() => onHandleTemplatePortrait(item, canvas)}
                    />
                  </Col>
                )}
              </Row>
              <CustomGap height='2rem' />
            </Content>
            <Content>
              <p className='title-bar'>
                A4-Landscape
              </p>
              <Row gutter={16}>
                {landscape?.map((item, index) =>
                  <Col md={8} key={index} className='mt-3'>
                    <Image
                      preview={false}
                      src={ILTemplate01}
                      alt={index}
                      onClick={() => onHandleTemplateLandscape(item, canvas)}
                    />
                  </Col>
                )}
              </Row>
            </Content>
          </Layout>
        )
      case 'Texts':
        return (
          <Layout className='content-text'>
            <Input
              value={textCertificates.title}
              onChange={({ target }) => setTextCertificates({ ...textCertificates, title: target.value })}
              placeholder='[add your title]'
              suffix={<PlusSquareOutlined onClick={() => handleAddTextItem('title')} />}
            />
            <CustomGap height='0.5rem' />
            <Input
              value={textCertificates.presentPath}
              onChange={({ target }) => setTextCertificates({ ...textCertificates, presentPath: target.value })}
              placeholder='[add your presentPath]'
              suffix={<PlusSquareOutlined onClick={() => handleAddTextItem('presentPath')} />}
            />
            <CustomGap height='0.5rem' />
            <Input
              value={textCertificates.recipient}
              onChange={({ target }) => setTextCertificates({ ...textCertificates, recipient: target.value })}
              placeholder='[add your recipient]'
              suffix={<PlusSquareOutlined onClick={() => handleAddTextItem('recipient')} />}
            />
            <CustomGap height='0.5rem' />
            <Input
              value={textCertificates.event}
              onChange={({ target }) => setTextCertificates({ ...textCertificates, event: target.value })}
              placeholder='[add your event]'
              suffix={<PlusSquareOutlined onClick={() => handleAddTextItem('event')} />}
            />
            <CustomGap height='0.5rem' />
            <Input
              value={textCertificates.description}
              onChange={({ target }) => setTextCertificates({ ...textCertificates, description: target.value })}
              placeholder='[add your description]'
              suffix={<PlusSquareOutlined onClick={() => handleAddTextItem('description')} />}
            />
            <CustomGap height='0.5rem' />
            <Input
              value={textCertificates.date}
              onChange={({ target }) => setTextCertificates({ ...textCertificates, date: target.value })}
              placeholder='[Date]'
              suffix={<PlusSquareOutlined onClick={() => handleAddTextItem('date')} />}
            />
            <CustomGap height='0.5rem' />
            <Input
              value={textCertificates.panitia}
              onChange={({ target }) => setTextCertificates({ ...textCertificates, panitia: target.value })}
              placeholder='[Panitia Name]'
              suffix={<PlusSquareOutlined onClick={() => handleAddTextItem('panitia')} />}
            />
            <CustomGap height='2rem' />
            <Button
              onClick={() => onHandleAddText(canvas)}
              icon={<FontSizeOutlined />}
            >
              Custom Text
            </Button>
          </Layout>
        )
      case 'Elements':
        return (
          <Layout className='content-element'>
            <Row align='middle' justify='center'>
              {shapeElements.map(item =>
                <Col
                  key={item}
                  md={6}
                  span={24}
                  align='center'
                  justify='center'
                >
                  <Content
                    className={item}
                    onClick={(e) =>
                      onHandleShapeElement(e, canvas)}
                  />
                </Col>
              )}
            </Row>
            <CustomGap height='2rem' />
            <Content align='middle' justify='center'>
              <Button
                type='primary'
                icon={<SolutionOutlined />}
                block
              >
                Add Signature
              </Button>
              <CustomGap height='2rem' />
              <Button
                block
                className='button-upload'
                icon={<UploadOutlined />}
              >
                Upload Images
                <input
                  type='file'
                  onChange={(e) => onHandleAddImage(e, canvas)}
                />
              </Button>
              <CustomGap height='2rem' />
              <Button
                block
                onClick={() => onHandleRectQrCode(canvas)}
                type='primary'
                icon={<QrcodeOutlined />}
              >
                QR CODE
              </Button>
            </Content>
          </Layout>
        )
      default:
        return null
    }
  }

  return (
    <Layout className='container-design-certificate'>
      <OrganizeNavbar />
      <CustomGap height='1rem' />
      <OrganizeTopBar
        canvas={canvas}
        format={paperTypeSize}
        orientation={paperOrientation}
        category={textCertificates.event}
        loading={loading}
      />
      <Layout className='container container-tools-design'>
        <Row align='top' justify='center'>
          <Col
            md={2}
            span={24}
            className='container-sidebar'
          >
            <Menu
              mode='inline'
              defaultSelectedKeys={['1']}
              onClick={({ key }) => setSelectedMenuItem(key)}
            >
              {menu.map(item =>
                <Menu.Item key={item}>
                  {item}
                </Menu.Item>
              )}
            </Menu>
          </Col>
          <Col
            md={6}
            span={24}
            className='container-actions'
          >
            <p className='title-bar'>
              {selectedMenuItem}
            </p>
            <Divider />
            {componentsSwtich(selectedMenuItem)}
          </Col>
          <Col
            md={16}
            span={24}
            className='container-canvas'
          >
            <Row
              className='canvas-toolbar'
              justify='space-between'
              align='middle'
            >
              <Content className='toolbar-picker'>
                <Row align='middle' justify='space-between'>
                  <Popover
                    trigger='click'
                    visible={visible.fillColor}
                    content={<ChromePicker
                      color={colorPicker.backgroundColor}
                      onChange={(hex) => onHandleFillColor(hex, canvas)}
                             />}
                    onVisibleChange={() => setVisible(!visible.fillColor)}
                  >
                    <Content
                      className='color-picker'
                      style={{ backgroundColor: colorPicker.fillColor }}
                    />
                  </Popover>
                  <Divider type='vertical' />
                  <Popover
                    content={<ChromePicker
                      color={colorPicker?.backgroundColor}
                      onChange={(hex) => onHandleBorderColor(hex, canvas)}
                             />}
                    trigger='click'
                    visible={visible?.borderColor}
                    onVisibleChange={() => setVisible(!visible?.borderColor)}
                  >
                    <Content
                      className='color-style'
                      style={{ backgroundColor: colorPicker?.borderColor }}
                    />
                  </Popover>
                  <Divider type='vertical' />
                  <Select
                    size='small'
                    placeholder='Size'
                    style={{ fontSize: 12 }}
                    onChange={(e) => onHandleFontSize(e, canvas)}
                  >
                    {fontSize.map(item =>
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    )}
                  </Select>
                  <Divider type='vertical' />
                  <Select
                    size='small'
                    placeholder='Font Family'
                    style={{ fontSize: 12 }}
                    onChange={(e) => onHandleFontFamily(e, canvas)}
                  >
                    {fontFamily.map(item =>
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    )}
                  </Select>
                </Row>
              </Content>
              <Divider type='vertical' />
              <Content className='toolbar-style'>
                <Row justify='space-between' align='middle'>
                  {iconStyle.map((item, index) =>
                    <Content key={index}>
                      <Image
                        key={index}
                        src={item}
                        preview={false}
                        onClick={() => onHandleStyleObj(index, canvas)}
                      />
                    </Content>
                  )}
                </Row>
              </Content>
            </Row>
            <Row className='canvas-paper'>
              {paperSize || paperOrientation
                ? (
                  <Content key='1'>
                    <Button icon={<LayoutTwoTone />}>
                      {paperTypeSize} - {paperOrientation}
                    </Button>
                    <CustomGap height='1rem' />
                    <Row justify='center'>
                      <canvas id='canvas' />
                    </Row>
                  </Content>
                  )
                : null}
            </Row>
          </Col>
        </Row>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = ({ reducerCertificate }) => {
  return { ...reducerCertificate }
}

export default connect(mapStateToProps)(CertificatesDesign)

CertificatesDesign.propTypes = {
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  portrait: PropTypes.array,
  landscape: PropTypes.array
}
