/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Link,
  useLocation
} from 'react-router-dom'
import {
  PageHeader,
  Popover,
  Button,
  Menu,
  Row
} from 'antd'
import {
  PlusCircleOutlined,
  CiCircleOutlined,
  SearchOutlined,
  LogoutOutlined,
  BellOutlined,
  UserOutlined
} from '@ant-design/icons'
import { CustomGap, CustomInput } from 'src/components'

export const CustomHeader = ({ simple }) => {
  const { pathname } = useLocation()
  const breadcrumb = () => (
    <p className='title-bar' key='title'>
      Dashboard{' '}
      {pathname?.length > 1
        ? (
          <span>
            / {pathname.substr(1, pathname.length)}
          </span>
          )
        : false}
    </p>
  )
  const input = () => (
    <CustomInput
      size='large'
      style={{ width: '30rem' }}
      prefix={<SearchOutlined />}
      placeholder='search product by name date or quantitas'
    />
  )

  const onHandleLogout = () => {
    localStorage.clear()
    window.location.href = '/login'
  }

  const actions = () => (
    <Row
      key='5'
      align='middle'
      justify='space-around'
    >
      <BellOutlined style={{ fontSize: 20 }} />
      <CustomGap width='2rem' />
      <CiCircleOutlined style={{ fontSize: 20 }} />
      <CustomGap width='.5rem' />
      Diko Mahendra
      <CustomGap width='1rem' />
      <Popover
        overlayClassName='popover-logout'
        placement='bottomLeft'
        trigger='click'
        content={[
          <Menu key='menu'>
            <Menu.Item key='profile' icon={<UserOutlined />}>
              <Link to='/profile'>
                Profile
              </Link>
            </Menu.Item>
            <Menu.Item
              key='sign-out'
              icon={<LogoutOutlined />}
              onClick={onHandleLogout}
            >
              Sign Out
            </Menu.Item>
          </Menu>
        ]}
      >
        <Button type='primary'>
          <LogoutOutlined />
        </Button>
      </Popover>
    </Row>
  )

  const search = () =>
    <Link to='/products/create'>
      <Button icon={<PlusCircleOutlined />}>
        Create Products
      </Button>
    </Link>
  return (
    <PageHeader
      ghost={false}
      onBack={simple ? false : () => window.history.back()}
      title={simple ? input() : breadcrumb()}
      extra={simple ? search() : actions()}
    />
  )
}

CustomHeader.propTypes = {
  simple: PropTypes.bool
}
