import React from 'react'
import {
  Card,
  Button,
  Layout,
  Col,
  Row,
  Image
} from 'antd'

import LayoutProfile from 'src/layout/profile'
import { CustomGap } from 'src/components'
import { ICSignature } from 'src/assets/images'

export default function Signature () {
  return (
    <LayoutProfile>
      <CustomGap height='2rem' />
      <Layout className='content-signature'>
        <Col md={12}>
          <Row gutter={16}>
            <Col md={12}>
              <p className='title'>
                Electric Signature
              </p>
              <Card hoverable>
                <Image
                  preview={false}
                  src={ICSignature}
                  alt='signature-1'
                />
              </Card>
              <CustomGap height='1rem' />
              <Row
                align='middle'
                justify='end'
              >
                <Button className='button reset'>
                  Reset
                </Button>
                <CustomGap width='10px' />
                <Button type='primary' className='button save'>
                  Save
                </Button>
              </Row>
            </Col>
            <Col md={12}>
              <p className='title'>
                Digital Signature
              </p>
              <Card actions={false}>
                <Image
                  preview={false}
                  src={ICSignature}
                  alt='signatuure-1'
                />
              </Card>
              <CustomGap height='1rem' />
              <Row
                align='middle'
                justify='end'
              >
                <Button className='button reset'>
                  Reset
                </Button>
                <CustomGap width='10px' />
                <Button type='primary' className='button save'>
                  Save
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Layout>
    </LayoutProfile>
  )
}
