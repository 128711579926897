import React from 'react'
import { Layout, Form, Col, Button } from 'antd'

import {
  CustomGap,
  CustomInput,
  CustomFormItem
} from 'src/components'
import LayoutProfile from 'src/layout/profile'

const ChangePassword = () => {
  const onFinish = (params) => {}

  return (
    <LayoutProfile>
      <CustomGap height='2rem' />
      <Layout className='content-change-password'>
        <Col md={8}>
          <Form onFinish={onFinish} layout='vertical'>
            <CustomFormItem label='Old Password'>
              <CustomInput placeholder='Your Password' password />
            </CustomFormItem>
            <CustomFormItem label='Confirm Password'>
              <CustomInput placeholder='Your Password' password />
            </CustomFormItem>
            <CustomFormItem label='New Password'>
              <CustomInput placeholder='Your Password' password />
            </CustomFormItem>
            <Button className='button' block htmlType='submit'>
              Save Password
            </Button>
          </Form>
        </Col>
      </Layout>
      <Col md={8} />
    </LayoutProfile>
  )
}

export default ChangePassword
