/* eslint-disable no-undef */
import {
  all,
  put,
  call,
  takeEvery
} from 'redux-saga/effects'

import authenticationService from 'src/services/api/auth'

import { actions } from './actions'

function load (state) {
  return setState({ loading: state })
}

function saveItems (state) {
  return setState(state)
}

function setState (payload) {
  return put({
    type: 'auth/set_state',
    payload
  })
}

export function * signIn ({ payload }) {
  const { form } = payload
  const { login, profile } = authenticationService

  yield load(true)
  const { status, data } = yield call(login, form)

  if (status === 202) {
    localStorage.setItem('isAuthorized', data.data.token)
    const isMe = yield call(profile, data.data.token)

    if (isMe.status === 200) {
      localStorage.setItem('isAuthenticated', JSON.stringify(isMe.data.data))
      yield saveItems({
        loading: false,
        token: data.data.token,
        isUser: isMe.data.data
      })
    }
  } else {
    yield saveItems({
      loading: false,
      notice: data.message
    })
  }
}

export function * register ({ payload }) {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true
    }
  })

  const response = yield call(authenticationService.register, payload)
  const sttCode = response?.data.statusCode
  const msg = response?.data.message

  if (sttCode === 202) {
    window.location = '/login'
    yield put({
      type: 'auth/SET_STATE',
      payload: { loading: false }
    })
  } else {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        notice: msg,
        loading: false
      }
    })
  }
}

export function * logout () {
  localStorage.removeItem()
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.sign_in, signIn),
    takeEvery(actions.register, register)
  ])
}
