import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { CameraOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  Form,
  Modal,
  Upload,
  Select,
  Button,
  Input,
  Col,
  Row,
  Divider
} from 'antd'
import { connect } from 'react-redux'
import { rules } from 'src/utils/rules'

const Text = ({ children }) => <p style={{ margin: 0 }}>{children}</p>
const { Option } = Select

const ModalCreateSpeaker = ({ dispatch, token, eventId, list, speaker, loadingSpeaker }) => {
  const [form] = Form.useForm()

  const onHandleSubmit = () => {
    form.validateFields().then(form => {
      const newForm = {
        ...form,
        avatar: form?.avatar?.file?.originFileObj
      }

      dispatch({
        type: 'event/create_speaker',
        payload: { token, eventId, newForm }
      })
    })
  }

  const onHandleVisible = () => {
    dispatch({
      type: 'event/set_state',
      payload: {
        speaker: {
          visible: false,
          data: speaker.data
        },
        session: { data: list }
      }
    })
  }

  useEffect(() => {
    if (!speaker.visible) form.resetFields()
  }, [speaker.visible])

  return (
    <Modal
      title='Create Speaker'
      footer={false}
      visible={speaker.visible}
      width={800}
      onCancel={onHandleVisible}
    >
      <Form
        layout='vertical'
        form={form}
        onFinish={onHandleSubmit}
      >
        <Row>
          <Col span={24} md={12}>
            <Form.Item
              name='avatar'
              label='Avatar'
              rules={[rules.required]}
            >
              <Upload name='avatar' listType='picture-card'>
                <CameraOutlined style={{ fontSize: 24 }} />
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label='Name'
              name='name'
              rules={[rules.required]}
            >
              <Input size='large' placeholder='speaker name' />
            </Form.Item>
            <Form.Item
              label='Topic'
              name='topic'
              rules={[rules.required]}
            >
              <Input size='large' placeholder='topic about' />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label='Select Session'
          name='sessionIds'
          rules={[rules.required]}
        >
          <Select
            mode='multiple'
            size='large'
            optionLabelProp='label'
            placeholder='Please Select Session'
            style={{ width: '100%' }}
          >
            {list?.items?.map((item, index) =>
              <Option
                key={index}
                value={item.id}
                label={item.name}
              >
                <Row justify='space-between' align='middle'>
                  <Row align='middle'>
                    <a href='#'>
                      {item.name}
                    </a>
                  </Row>
                  <Row align='middle'>
                    <Text>
                      {moment(item.startTime).format('ll')}{' '}
                    </Text>
                    <Divider type='vertical' />
                    <Text>
                      {moment(item.startTime).format('LT')}
                    </Text>
                    <Divider type='vertical' />
                    <Text>
                      {moment(item.endTime).format('LT')}
                    </Text>
                  </Row>
                </Row>
              </Option>
            )}
          </Select>
        </Form.Item>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label='No Whatsapp'
              name='phone'
              rules={[rules.required]}
            >
              <Input
                addonBefore='+62'
                size='large'
                style={{ width: '100%' }}
                placeholder='849038091222'
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label='Email'
              name='email'
              rules={[rules.required, rules.email]}
            >
              <Input size='large' placeholder='speaker@mail.com' />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label='Description' name='description'>
          <Input.TextArea size='large' placeholder='write your description' />
        </Form.Item>
        <Row justify='end'>
          <Button
            htmlType='submit'
            type='primary'
            loading={loadingSpeaker}
            icon={<PlusCircleOutlined />}
          >
            Create Moderator
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(ModalCreateSpeaker)

ModalCreateSpeaker.propTypes = {
  dispatch: PropTypes.func,
  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  token: PropTypes.string,
  speaker: PropTypes.object,
  loadingSpeaker: PropTypes.bool,
  eventId: PropTypes.string
}

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ])
}
