import React from 'react'
import ProptTypes from 'prop-types'
import { Layout } from 'antd'

import { CustomSidebar, CustomHeader } from 'src/components'
const { Content, Footer } = Layout

const LayoutDashboard = ({ children }) => {
  return (
    <Layout className='container-layout'>
      <CustomSidebar />
      <Layout className='site-layout' style={{ marginLeft: 200 }}>
        <CustomHeader />
        <Content style={{ overflow: 'initial' }}>
          <div className='site-layout-background' style={{ padding: 24 }}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', fontSize: 12 }}>
          Certie ©2021 Created by ♥ Diko Mahendra
        </Footer>
      </Layout>
    </Layout>
  )
}

export default LayoutDashboard

LayoutDashboard.propTypes = {
  children: ProptTypes.oneOfType([
    ProptTypes.string,
    ProptTypes.array,
    ProptTypes.object
  ])
}
