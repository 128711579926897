import React from 'react'
import { Form } from 'antd'
import PropTypes from 'prop-types'

const CustomFormItem = ({
  name,
  label,
  children,
  className,
  noRequired,
  rules
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      className={className}
      rules={[noRequired ? false : rules]}
    >
      {children}
    </Form.Item>
  )
}

export default CustomFormItem

CustomFormItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  noRequired: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ]),
  rules: PropTypes.object
}
