import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

const CustomButton = ({
  className,
  children,
  disabled,
  onClick,
  htmlType,
  block,
  icon,
  size,
  type,
  href,
  to
}) => {
  return (
    <Link to={to}>
      <Button
        className={className}
        disabled={disabled}
        htmlType={htmlType}
        onClick={onClick}
        block={block}
        icon={icon}
        size={size}
        type={type}
        href={href}
      >
        {children}
      </Button>
    </Link>
  )
}

CustomButton.defaultProps = {
  className: 'd-flex align-items-center'
}

export default CustomButton

CustomButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  htmlType: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  type: PropTypes.string,
  href: PropTypes.string,
  block: PropTypes.bool,
  icon: PropTypes.any,
  to: PropTypes.string
}
