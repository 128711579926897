import React from 'react'
import { Input, Tooltip } from 'antd'
import PropTypes from 'prop-types'

const CustomInput = ({
  visibilityToggle,
  placeholder,
  tooltipText,
  iconRender,
  allowClear,
  maxLength,
  className,
  disabled,
  onChange,
  password,
  prefix,
  suffix,
  colors,
  style,
  value,
  type,
  size
}) => {
  switch (password) {
    case true:
      return (
        <Input.Password
          visibilityToggle={visibilityToggle}
          placeholder={placeholder}
          allowClear={allowClear}
          iconRender={iconRender}
          maxLength={maxLength}
          className={className}
          disabled={disabled}
          onChange={onChange}
          prefix={prefix}
          suffix={suffix}
          type={type}
          size={size}
        />
      )
    default:
      return (
        <Tooltip
          title={tooltipText ? <small>{tooltipText}</small> : null}
          placement='top'
          color={colors}
          trigger='hover'
        >
          <Input
            placeholder={placeholder}
            allowClear={allowClear}
            maxLength={maxLength}
            className={className}
            onChange={onChange}
            disabled={disabled}
            value={value}
            prefix={prefix}
            type={type}
            size={size}
            style={style}
          />
        </Tooltip>
      )
  }
}

export default CustomInput

CustomInput.defaultProps = {
  size: 'large',
  className: 'rounded text-sm'
}

CustomInput.propTypes = {
  visibilityToggle: PropTypes.bool,
  tooltipText: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  iconRender: PropTypes.func,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  password: PropTypes.bool,
  colors: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
  type: PropTypes.string,
  suffix: PropTypes.object,
  prefix: PropTypes.object,
  value: PropTypes.string
}
