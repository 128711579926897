/* eslint-disable no-undef */
import React from 'react'
import {
  Route,
  Routes,
  BrowserRouter
} from 'react-router-dom'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'

import {
  Event,
  Login,
  QRCode,
  ListProducts,
  NotFound,
  Register,
  Profile,
  Dashboard,
  Signatured,
  CertificateMenu,
  CertificatesDesign,
  CertificateEvent,
  ChangePassword,
  CreateEvent,
  UpdateEvent,
  ListCertificates,
  CertificatePreview,
  CreateProduct
} from './pages'

import { GuesRoute, PrivateRoute } from 'src/middleware/ProtectedRoute'

const Routers = () => {
  const token = localStorage.getItem('isAuthorized')

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <PrivateRoute>
              <Dashboard token={token} />
            </PrivateRoute>
         }
        />
        <Route
          path='/list-event'
          element={<PrivateRoute><Event token={token} /></PrivateRoute>}
        />
        <Route
          path='/list-event/:id'
          element={<PrivateRoute><UpdateEvent token={token} /> </PrivateRoute>}
        />
        <Route
          path='/list-event/certificate/:id'
          element={<PrivateRoute><ListCertificates token={token} /> </PrivateRoute>}
        />
        <Route
          path='/list-event/certificate/:id/details'
          element={<PrivateRoute><CertificateEvent token={token} /></PrivateRoute>}
        />
        <Route
          path='/create-event'
          element={<PrivateRoute><CreateEvent token={token} /></PrivateRoute>}
        />
        <Route
          path='/certificates'
          element={<PrivateRoute><CertificateMenu token={token} /></PrivateRoute>}
        />
        <Route
          path='/certificates/:id'
          element={<PrivateRoute><CertificatePreview token={token} /></PrivateRoute>}
        />
        <Route
          path='/design-certificate'
          element={<PrivateRoute><CertificatesDesign token={token} /></PrivateRoute>}
        />
        <Route
          path='/products'
          element={<PrivateRoute> <ListProducts token={token} /> </PrivateRoute>}
        />
        <Route
          path='/products/create'
          element={<PrivateRoute> <CreateProduct token={token} /> </PrivateRoute>}
        />
        <Route
          path='/qr-code'
          element={<PrivateRoute><QRCode token={token} /></PrivateRoute>}
        />
        <Route
          path='/profile'
          element={<PrivateRoute><Profile token={token} /></PrivateRoute>}
        />
        <Route
          path='/profile-password'
          element={<PrivateRoute> <ChangePassword token={token} /> </PrivateRoute>}
        />
        <Route
          path='/profile-signature'
          element={<PrivateRoute><Signatured token={token} /></PrivateRoute>}
        />

        <Route path='/login' element={<GuesRoute><Login /></GuesRoute>} />
        <Route path='/register' element={<GuesRoute><Register /></GuesRoute>} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

const mapStateToProps = ({ reducerAuth }) => {
  return { ...reducerAuth }
}

export default connect(mapStateToProps)(withCookies(Routers))
