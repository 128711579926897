import React from 'react'
import { Layout } from 'antd'

import { CustomGap } from '../components'
import CustomLayout from 'src/layout/dashboard'

const QRCode = () => {
  return (
    <CustomLayout>
      <CustomGap height='1rem' />
      <Layout className='container'>
        <h1>hello world</h1>
      </Layout>
    </CustomLayout>
  )
}

export default QRCode

QRCode.propTypes = {}
