import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  PageHeader,
  Layout,
  Button,
  Card,
  Col,
  Row,
  Image,
  Space,
  Input,
  Modal
} from 'antd'
import {
  CopyOutlined,
  ArrowLeftOutlined,
  EditOutlined,
  ArrowRightOutlined,
  RetweetOutlined
} from '@ant-design/icons'
import { CustomGap } from 'src/components'

const { Content } = Layout

const StepSelectCertificates = ({
  dispatch,
  token,
  event,
  planCertificates,
  eventId,
  step
}) => {
  const [items] = useState([
    { title: 'Registrant', design: null },
    { title: 'Host', design: null },
    { title: 'Committee', design: null },
    { title: 'Speaker', design: null },
    { title: 'Moderator', design: null }
  ])

  useEffect(() => {
    if (!items.design) {
      dispatch({
        type: 'event/set_state',
        payload: {
          planCertificates: {
            viisble: false
          }
        }
      })
    }
  }, [items.design])

  const onHandlePlantCertie = (type) => {
    dispatch({
      type: 'event/get_certificates',
      payload: { token, type }
    })
  }

  const onHandleChooseCertificate = (params, type) => {
    const index = items.findIndex(item => item.title === type)
    items[index] = { title: items[index].title, design: params }

    dispatch({
      type: 'event/set_planCertificate',
      payload: {
        token,
        eventId,
        type: type.toLowerCase(),
        plainCertieId: params.id
      }
    })
  }

  const onHandleCloseModal = (params) => {
    dispatch({
      type: 'event/set_state',
      payload: {
        planCertificates: {
          visible: params
        }
      }
    })
  }

  const setSaveState = (payload) => dispatch({
    type: 'event/set_state',
    payload
  })

  const onHandleStep = (type) => {
    switch (type) {
      case 'next':
        if (event) {
          return setSaveState({ update: { step: 5, event } })
        } else return setSaveState({ step: step + 1 })
      case 'previous':
        if (event) {
          return setSaveState({ update: { step: 3, event } })
        } else return setSaveState({ step: step - 1 })
      default:
        return false
    }
  }

  return (
    <>
      <Content className='container-select-certificates'>
        <PageHeader
          ghost={false}
          subTitle={[<p key='' className='big-title'>Select Certificates</p>]}
        />
        <CustomGap height='1rem' />
        <Row gutter={16}>
          {items.map(({ title, design }, index) =>
            <Col key={index} span={24} md={6}>
              <Card
                className='card-certificates'
                hoverable
                cover={<img src={design?.image} />}
              >
                <Row justify='space-between' className='action'>
                  {title} Participant
                  <Button
                    size='small'
                    type='primary'
                    onClick={() => onHandlePlantCertie(title)}
                  >
                    <EditOutlined />
                  </Button>
                </Row>
              </Card>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24} md={12} className='generate-url'>
            <Input placeholder='https://gitlab.com/qodrbee/generate-qr-label-qurosh' />
            <CustomGap width='1rem' />
            <Button>
              <CopyOutlined />
            </Button>
          </Col>
        </Row>
        <Row
          justify='space-between'
          gutter={16}
          className='form-footer'
        >
          <Button icon={<RetweetOutlined />}>
            Reset
          </Button>
          <Space justify='end'>
            <Button
              onClick={() => onHandleStep('previous')}
              icon={<ArrowLeftOutlined />}
            >
              Previous
            </Button>
            <Button
              type='primary'
              onClick={() => onHandleStep('next')}
              icon={<ArrowRightOutlined />}
            />
          </Space>
        </Row>
      </Content>
      <Modal
        title='Choose Template Design'
        visible={planCertificates.visible}
        maskClosable={false}
        wrapClassName='modal-select-certificate'
        onCancel={() => onHandleCloseModal(false)}
        width={900}
        footer={false}
      >
        <Row gutter={16}>
          {planCertificates?.items?.map((item, index) =>
            <Col key={index} span={24} md={8}>
              <Card
                className='card-select-certificate'
                hoverable
                actions={false}
                onClick={() => onHandleChooseCertificate(item, planCertificates.type)}
              >
                <Image src={item.image} preview={false} />
              </Card>
            </Col>
          )}
        </Row>
      </Modal>
    </>
  )
}

const mapStateProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateProps)(StepSelectCertificates)

StepSelectCertificates.propTypes = {
  dispatch: PropTypes.func,
  event: PropTypes.object,
  token: PropTypes.string,
  planCertificates: PropTypes.object,
  eventId: PropTypes.string,
  step: PropTypes.number
}
