import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Form,
  Input,
  Modal,
  Button,
  TimePicker,
  DatePicker
} from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { rules } from 'src/utils/rules'
import { CustomGap } from 'src/components'

const ModalCreateSession = ({ dispatch, loading, eventId, session, token }) => {
  const [form] = Form.useForm()
  const [datePicker, setDatePicker] = useState(null)
  const [timePicker, setTimePicker] = useState({
    startTime: null,
    endTime: null
  })

  useEffect(() => {
    if (!session.visible) form.resetFields()
    form.resetFields()
  }, [session.visible])

  const onHandleVisible = (visible) => {
    dispatch({
      type: 'event/set_state',
      payload: {
        session: { data: session.data, visible }
      }
    })
  }

  const onHandleSubmit = () => {
    form.validateFields().then(form => {
      const toIsoString = date => new Date(date).toISOString()
      const concatDate = date => datePicker.concat(' ', date)
      const data = {
        ...form,
        ...timePicker,
        startTime: toIsoString(concatDate(timePicker.startTime)),
        endTime: toIsoString(concatDate(timePicker.endTime))
      }

      dispatch({
        type: 'event/create_session',
        payload: {
          eventId,
          token,
          data: data
        }
      })
    })
  }

  return (
    <Modal
      title='Create Session'
      style={{ top: 20 }}
      visible={session.visible}
      width={600}
      footer={false}
      maskClosable={false}
      onCancel={() => onHandleVisible(false)}
    >
      <Form
        layout='vertical'
        form={form}
        onFinish={onHandleSubmit}
      >
        <Form.Item
          name='name'
          label='Session Name'
          rules={[rules.required]}
        >
          <Input size='large' />
        </Form.Item>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              name='startTime'
              label='Start Date'
              rules={[rules.required]}
            >
              <DatePicker
                size='large'
                style={{ width: '100%' }}
                onChange={(date, dateString) => setDatePicker(dateString)}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name='endTime'
              label='Start Time - End Time'
              rules={[rules.required]}
            >
              <TimePicker.RangePicker
                format='HH:mm'
                size='large'
                style={{ width: '100%' }}
                onChange={(time, timeString) =>
                  setTimePicker({
                    ...timePicker,
                    startTime: timeString[0],
                    endTime: timeString[1]
                  })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name='description'
          label='Description'
          rules={[rules.required]}
        >
          <Input.TextArea size='large' />
        </Form.Item>
        <CustomGap height='2rem' />
        <Row justify='end'>
          <Button
            htmlType='submit'
            type='primary'
            icon={<PlusCircleOutlined />}
          >
            Create Session
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(ModalCreateSession)

ModalCreateSession.propTypes = {
  session: PropTypes.object,
  loading: PropTypes.bool,
  token: PropTypes.string,
  eventId: PropTypes.string,
  dispatch: PropTypes.func
}
