import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Form,
  Table,
  Avatar,
  Button,
  Select,
  Layout,
  Space,
  Row,
  Col,
  Image,
  Card,
  Popconfirm,
  Popover,
  Modal,
  Input,
  Upload
} from 'antd'
import {
  MoreOutlined,
  SyncOutlined,
  CameraOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  FormOutlined,
  DeleteFilled,
  UserOutlined,
  EditFilled
} from '@ant-design/icons'

import {
  CustomGap,
  CustomEmpty,
  CustomHeaderCommitte
} from 'src/components'
import { rules } from 'src/utils/rules'

const { Content } = Layout

const StepCommittees = ({
  step,
  event,
  token,
  member,
  eventId,
  loading,
  dispatch,
  participantItems,
  committeesItems
}) => {
  const [form] = Form.useForm()
  const [formParticipant] = Form.useForm()
  const [updateCommitte, setUpdateCommitte] = useState({
    id: null,
    visible: false,
    data: null
  })
  const [updateParticipant, setUpdateParticipant] = useState({
    id: null,
    visible: false,
    data: null
  })
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>
    },
    {
      title: 'position',
      dataIndex: 'position',
      key: 'position'
    },
    {
      title: 'avatar',
      dataIndex: 'position',
      key: 'position',
      align: 'center',
      render: file => <Avatar icon={<UserOutlined />} />
    },
    {
      title: 'company',
      dataIndex: 'company',
      key: 'company'
    },
    {
      title: 'email',
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: 'number',
      key: 'phone',
      dataIndex: 'phone'
    },
    {
      title: 'Action',
      align: 'center',
      key: 'action',
      render: (text, object) => (
        <Space size='middle'>
          <Button
            icon={<EditFilled />}
            className='button-update'
            onClick={() => onHandleUpdate('committe', object)}
          >
            Update
          </Button>
          <Popconfirm
            title='Are you sure to delete this?'
            placement='leftTop'
            okText='Yes'
            cancelText='Cancel'
            onCancel={() => {}}
            onConfirm={() => onHandleDelete(object, 'committe')}
          >
            <Button
              loading={loading}
              icon={<DeleteFilled />}
              className='button-delete'
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'event/get_participants',
      payload: { eventId, token }
    })

    dispatch({
      type: 'event/get_committees',
      payload: { eventId, token }
    })
  }, [])

  const setSaveState = (payload) => dispatch({
    type: 'event/set_state',
    payload
  })

  const onHandleStep = (type, step) => {
    switch (type) {
      case 'previous':
        if (event) {
          return setSaveState({ update: { step: 0, event } })
        } else return setSaveState({ step: step - 1 })
      case 'next':
        if (event) {
          return setSaveState({ update: { step: 2, event } })
        } else return setSaveState({ step: step + 1 })
      default:
        return false
    }
  }

  const onHandleDelete = ({ id }, type) => {
    switch (type) {
      case 'participant':
        return dispatch({
          type: 'event/delete_participant',
          payload: {
            participantId: id,
            eventId,
            token
          }
        })
      case 'committe':
        return dispatch({
          type: 'event/delete_committe',
          payload: {
            token,
            eventId,
            committeId: id
          }
        })
      default:
        return false
    }
  }

  const onHandleUpdate = (type, params) => {
    switch (type) {
      case 'participant':
        return setUpdateParticipant({
          ...updateParticipant,
          visible: true,
          id: params.id,
          data: params
        })
      case 'committe':
        return setUpdateCommitte({
          ...updateCommitte,
          visible: true,
          data: params,
          id: params.id
        })
      default:
        return null
    }
  }

  const onHandleSubmitUpdate = (type, params) => {
    switch (type) {
      case 'participant':
        return dispatch({
          type: 'event/update_participant',
          payload: {
            participantId: params.id,
            items: params.data,
            token,
            eventId
          }
        })
      case 'committe':
        return dispatch({
          type: 'event/update_committe',
          payload: {
            committeId: params.id,
            items: params.data,
            token,
            eventId
          }
        })

      default:
        return null
    }
  }
  return (
    <>
      <Content className='container-create-committees'>
        <CustomHeaderCommitte
          token={token}
          eventId={eventId}
          member={member}
        />
        <CustomGap height='1rem' />
        <p className='big-title'>
          Participants
        </p>
        {participantItems?.items?.length !== 0 ? null : <CustomEmpty />}
        <Content className='wrapper-content-participant'>
          <Row gutter={16}>
            {participantItems?.items?.map((item, key) =>
              <Col key={key} span={24} md={8}>
                <Card className='card-participant' hoverable>
                  <Row align='middle' justify='start'>
                    <Avatar size={54} icon={<Image src={item.avatar} />} />
                    <Content className='card-participant-description'>
                      <p className='title'>
                        {item.name}
                      </p>
                      <Row justify='space-between' align='middle'>
                        <p className='email'>
                          {item.email}
                        </p>
                        <Popover
                          trigger='hover'
                          placement='topRight'
                          content={
                            <Space>
                              <Button
                                danger
                                icon={<DeleteFilled />}
                                onClick={() =>
                                  onHandleDelete(item, 'participant')}
                              >
                                Delete
                              </Button>
                              <Button
                                type='primary'
                                onClick={() => onHandleUpdate('participant', item)}
                                icon={<FormOutlined />}
                              >
                                Update
                              </Button>
                            </Space>
                          }
                        >
                          <MoreOutlined />
                        </Popover>
                      </Row>
                      <p className='type'> {item.type} </p>
                    </Content>
                  </Row>
                </Card>
              </Col>
            )}
          </Row>
        </Content>
        <CustomGap height='2rem' />
        <Content className='wrapper-content-committe'>
          <p className='big-title'>
            Committees
          </p>
          <Table
            rowKey='id'
            rowClassName='table-committees'
            columns={columns}
            loading={loading}
            dataSource={committeesItems?.items}
          />
        </Content>
        <Row
          justify='space-between'
          gutter={16}
          className='form-footer'
        >
          <Button icon={<SyncOutlined />}>
            Reset
          </Button>
          <Space justify='end'>
            <Button
              onClick={() => onHandleStep('previous', 1)}
              icon={<ArrowLeftOutlined />}
            >
              Previous
            </Button>
            <Button
              type='primary'
              onClick={() => onHandleStep('next', 1)}
              icon={<ArrowRightOutlined />}
            />
          </Space>
        </Row>
      </Content>

      {/* update participant */}
      <Modal
        visible={updateParticipant.visible}
        title='Update Participant'
        footer={false}
        onCancel={() => setUpdateParticipant({ visible: false })}
      >
        <Form
          form={formParticipant}
          requiredMark={false}
          layout='vertical'
          onFinish={() => onHandleSubmitUpdate('participant', updateParticipant)}
        >
          <Form.Item label='Upload Avatar' rules={[rules.required]}>
            <Upload listType='picture-card'>
              <CameraOutlined style={{ fontSize: 20 }} />
            </Upload>
          </Form.Item>
          <Row gutter={8}>
            <Col span={24} md={12}>
              <Form.Item label='Name' rules={[rules.required]}>
                <Input
                  size='large'
                  value={updateParticipant?.data?.name}
                  onChange={({ target: { value } }) => {
                    setUpdateParticipant({
                      ...updateParticipant,
                      data: { ...updateParticipant.data, name: value }
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label='Email' rules={[rules.required, rules.email]}>
                <Input
                  size='large'
                  value={updateParticipant?.data?.email}
                  onChange={({ target: { value } }) => {
                    return setUpdateParticipant({
                      ...updateParticipant,
                      data: { ...updateParticipant.data, email: value }
                    })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label='Choose Type' rules={[rules.required]}>
            <Select
              value={updateParticipant?.data?.type}
              optionFilterProp='children'
              onChange={(value) => setUpdateParticipant({
                ...updateParticipant,
                data: { ...updateParticipant.data, type: value }
              })}
            >
              <Select.Option value='HOST'>
                Host
              </Select.Option>
              <Select.Option value='CO_HOST'>
                Co-Host
              </Select.Option>
            </Select>
          </Form.Item>
          <Row justify='end'>
            <Button
              loading={loading}
              type='primary'
              htmlType='submit'
              icon={<FormOutlined />}
            >
              Update
            </Button>
          </Row>
        </Form>
      </Modal>

      {/* update committe */}
      <Modal
        title='Update Committe'
        footer={false}
        visible={updateCommitte.visible}
        onCancel={() => setUpdateCommitte({ visible: false })}
      >
        <Form
          form={form}
          requiredMark={false}
          layout='vertical'
          onFinish={() => onHandleSubmitUpdate('committe', updateCommitte)}
        >
          <Form.Item
            label='Name'
            rules={[rules.required]}
          >
            <Input
              size='large'
              value={updateCommitte?.data?.name}
              onChange={({ target }) => setUpdateCommitte({
                ...updateCommitte,
                data: { ...updateCommitte.data, name: target.value }
              })}
            />
          </Form.Item>
          <Form.Item
            label='Email'
            rules={[rules.required, rules.email]}
          >
            <Input
              size='large'
              value={updateCommitte?.data?.email}
              onChange={({ target }) => setUpdateCommitte({
                ...updateCommitte,
                data: { ...updateCommitte.data, email: target.value }
              })}
            />
          </Form.Item>
          <Form.Item
            label='Position'
            rules={[rules.required]}
          >
            <Input
              size='large'
              value={updateCommitte?.data?.position}
              onChange={({ target }) => setUpdateCommitte({
                ...updateCommitte,
                data: { ...updateCommitte.data, position: target.value }
              })}
            />
          </Form.Item>
          <Form.Item
            label='No Whatsapp'
            rules={[rules.required]}
          >
            <Input
              addonBefore='+62'
              size='large'
              style={{ width: '100%' }}
              value={updateCommitte.data?.phone}
              onChange={({ target }) => setUpdateCommitte({
                ...updateCommitte,
                data: { ...updateCommitte.data, phone: target.value }
              })}
            />
          </Form.Item>
          <Form.Item
            label='Company'
            rules={[rules.required]}
          >
            <Input
              size='large'
              value={updateCommitte?.data?.company}
              onChange={({ target }) => setUpdateCommitte({
                ...updateCommitte,
                data: { ...updateCommitte.data, company: target.value }
              })}
            />
          </Form.Item>
          <Row justify='end'>
            <Button
              type='primary'
              htmlType='submit'
              icon={<FormOutlined />}
              loading={loading}
            >
              Update
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(StepCommittees)

StepCommittees.propTypes = {
  step: PropTypes.number,
  event: PropTypes.object,
  dispatch: PropTypes.func,
  token: PropTypes.string,
  member: PropTypes.object,
  eventId: PropTypes.string,
  loading: PropTypes.bool,
  participantItems: PropTypes.object,
  committeesItems: PropTypes.object
}
