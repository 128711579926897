import React from 'react'
import { Button, Layout, Table } from 'antd'
import { Link, useLocation } from 'react-router-dom'

// import { CustomGap } from 'src/components'
import CustomLayout from 'src/layout/dashboard'
import { Content } from 'antd/lib/layout/layout'

export default function ListCertificates () {
  const path = useLocation()
  const eventId = path?.pathname?.split('/').pop()

  const dataSource = [
    {
      key: '1',
      group_name: 'Committe',
      certificate: 7
    },
    {
      key: '2',
      group_name: 'Participant',
      certificate: 50
    },
    {
      key: '3',
      group_name: 'Host',
      certificate: 2
    },
    {
      key: '4',
      group_name: 'Speaker',
      certificate: 3
    },
    {
      key: '5',
      group_name: 'Moderator',
      certificate: 1
    }
  ]

  const columns = [
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      key: '1'
    },
    {
      title: 'Certificate',
      dataIndex: 'certificate',
      key: '2'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: '3',
      render: (id) => (
        <Link to={`/list-event/certificate/${eventId}/details`}>
          <Button type='primary' className='rounded-pill'>
            View Certificates
          </Button>
        </Link>
      )
    }
  ]

  return (
    <CustomLayout>
      <Layout className='container-list-certicate-events'>
        <Content>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </Content>
      </Layout>
    </CustomLayout>
  )
}
