export const rules = {
  email: () => {
    return { type: 'email' }
  },
  required: () => {
    return { required: true }
  },
  date: () => {
    return { type: new Date() }
  },
  any: () => {
    return { type: 'any' }
  }
}
