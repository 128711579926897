import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Layout, Steps } from 'antd'
import { connect } from 'react-redux'

import {
  CreditCardTwoTone,
  ProfileTwoTone,
  IdcardTwoTone,
  PrinterTwoTone
} from '@ant-design/icons'

import {
  StepEvent,
  StepSession,
  StepCommittees,
  StepModeratorSpeaker,
  StepSelectCertificates,
  CustomFinished
} from 'src/components'
import CustomLayout from 'src/layout/dashboard'

const { Step } = Steps
const { Content } = Layout

const ContainerSteps = ({ event, step, token, member }) => {
  switch (step) {
    case 0:
      return <StepEvent event={event} token={token} />
    case 1:
      return <StepCommittees event={event} member={member} token={token} />
    case 2:
      return <StepSession event={event} token={token} />
    case 3:
      return <StepModeratorSpeaker event={event} token={token} />
    case 4:
      return <StepSelectCertificates event={event} token={token} />
    case 5:
      return <CustomFinished event={event} token={token} />
    default:
      return <StepEvent event={event} token={token} />
  }
}

const UpdateEvent = ({ dispatch, token, member, update }) => {
  const { id } = useParams()
  const { event } = update

  useEffect(() => {
    dispatch({
      type: 'event/get_member',
      payload: { token }
    })
    dispatch({
      type: 'event/get_event_one',
      payload: { token, eventId: id }
    })
  }, [])

  return (
    <CustomLayout>
      <Layout className='container-create-event'>
        <Content className='container-step'>
          <Steps>
            <Step
              title='General Information'
              icon={<ProfileTwoTone />}
            />
            <Step
              title='Committe Information'
              icon={<IdcardTwoTone />}
            />
            <Step
              title='Session Information'
              icon={<CreditCardTwoTone />}
            />
            <Step
              title='Moderator & Speaker'
              icon={<CreditCardTwoTone />}
            />
            <Step
              title='Choose Design'
              icon={<PrinterTwoTone />}
            />
            <Step
              title='Done'
              icon={<PrinterTwoTone />}
            />
          </Steps>
        </Content>
        <Content>
          <ContainerSteps
            step={update.step}
            event={event}
            token={token}
            member={member}
          />
        </Content>
      </Layout>
    </CustomLayout>
  )
}

UpdateEvent.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  update: PropTypes.object,
  member: PropTypes.object
}

ContainerSteps.propTypes = {
  step: PropTypes.number,
  token: PropTypes.string,
  member: PropTypes.object,
  event: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(UpdateEvent)
