import { actions } from './actions'

const initialState = {
  step: 0,
  loading: false,
  loadingSpeaker: false,
  loadingModerator: false,
  eventId: '4f49524c-c049-4441-a1fc-bf6abb72a37c',
  sessionId: null,
  detail: {
    visibleSpeaker: false,
    visibleModerator: false,
    data: []
  },
  session: {
    step: 0,
    data: [],
    visible: false
  },
  moderator: {
    data: [],
    visible: false
  },
  speaker: {
    data: [],
    visible: false
  },
  planCertificates: {
    visible: false,
    items: [],
    meta: []
  },
  update: {
    viisbleSpeaker: false,
    speaker: [],
    visibleModerator: false,
    moderator: [],
    event: [],
    step: 0
  },
  member: {
    visible: false,
    data: []
  }
}

const reducerEvent = (state = initialState, action) => {
  switch (action.type) {
    case actions.set_state:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export { reducerEvent }
