/* eslint-disable no-undef */
import {
  all,
  put,
  call,
  takeEvery
} from 'redux-saga/effects'
import { message } from 'antd'

import { actions } from 'src/redux/Events/actions'
import serviceEvent from 'src/services/api/events'
import committeService from 'src/services/api/events/committe'
import participantService from 'src/services/api/events/host'
import serviceSession from 'src/services/api/events/session'
import moderatorService from 'src/services/api/events/moderator'
import speakerService from 'src/services/api/events/speaker'
import serviceCertificate from 'src/services/api/certificate'
import memberService from 'src/services/api/events/member'

function load (state) {
  return setState(state)
}

function saveItems (state) {
  return setState(state)
}

function setState (payload) {
  return put({
    type: 'event/set_state',
    payload
  })
}

export function * getEvents ({ payload }) {
  yield load({ loading: true })
  const { get } = serviceEvent
  const { searchBy, token } = payload

  const { data: { data }, status } = yield call(get, { name: searchBy }, token)

  if (status === 200) {
    yield load(false)
    yield saveItems({ data })
  } yield load({ loading: false })
}

export function * getEventOne ({ payload }) {
  const { eventId, token } = payload

  const response = yield call(serviceEvent.getOne, token, eventId)
  if (response.status === 200) {
    yield saveItems({
      eventId: response.data.data.id,
      update: {
        event: response.data.data
      }
    })
  }
}

export function * createEvent ({ payload }) {
  yield load({ loading: true })
  const { data, token } = payload

  const newData = new FormData()
  newData.append('name', data.name)
  newData.append('startDate', data.startDate)
  newData.append('endDate', data.endDate)
  newData.append('type', data.type)
  newData.append('description', data.description)
  newData.append('organizerName', data.organizerName)
  newData.append('organizerImage', data.organizerImage)
  newData.append('banner', data.banner)
  newData.append('publisher', data.publisher)

  const response = yield call(serviceEvent.post, token, { data: newData })

  if (response.status === 202) {
    yield saveItems({
      step: 1,
      eventId: response.data.data.id
    })
    message.success('Event Succes Created!')
  } yield load({ loading: false })
}

export function * updateEvent ({ payload }) {
  yield load({ loading: true })
  const { eventId, token, data } = payload
  const response = yield call(serviceEvent.patch, token, { eventId, data })
  if (response.status === 202) {
    message.success('Update Event Success!')
  } yield load({ loading: false })
}

export function * deleteEvent ({ payload }) {
  yield load({ loading: true })
  const { token, eventId } = payload

  const { status } = yield call(serviceEvent.delete, eventId, token)

  if (status === 202) {
    yield put({
      type: 'event/get_events',
      payload: { token }
    })
    message.success('Success Delete Event!')
  } yield load({ loading: false })
}

export function * getParticipants ({ payload }) {
  yield load({ loading: true })
  const { get } = participantService
  const { eventId, token } = payload

  const { data: { data }, status } = yield call(get, eventId, token)

  if (status === 200) {
    yield load(false)
    yield saveItems({ participantItems: data })
  } yield load({ loading: false })
}

export function * createParticipant ({ payload }) {
  yield load({ loading: true })
  const { post } = participantService
  const {
    data: { avatar, name, email, type },
    token,
    eventId
  } = payload
  const images = avatar?.fileList[0]?.originFileObj

  const form = new FormData()
  form.append('avatar', images)
  form.append('name', name)
  form.append('email', email)
  form.append('type', type)

  const { status } = yield call(post, { data: form, eventId }, token)

  if (status === 202) {
    yield put({
      type: 'event/get_participants',
      payload: { eventId, token }
    })
    message.success('Create Participant Success!')
    yield load(false)
  } else {
    message.error('Create Participant Failed!')
    yield load({ loading: false })
  }
}

export function * updateParticipant ({ payload }) {
  yield load({ loading: true })
  const { token, eventId, participantId, items } = payload

  const { status } = yield call(participantService.patch, token, { eventId, participantId, items })

  if (status === 202) {
    yield put({
      type: 'event/get_participants',
      payload: { eventId, token }
    })
    message.success('Update Participant Success!')
  } else {
    message.error('Create Participant Failed!')
    yield load({ loading: false })
  }
}

export function * deleteParticipant ({ payload }) {
  yield load({ loading: true })
  const { participantId, eventId, token } = payload

  const { status } = yield call(participantService.delete, token, { participantId })

  if (status === 202) {
    yield put({
      type: 'event/get_participants',
      payload: { eventId, token }
    })
  } yield load({ loading: false })
}

export function * getCommittees ({ payload }) {
  yield load({ loading: true })
  const { get } = committeService
  const { token, eventId } = payload

  const { data: { data }, status } = yield call(get, eventId, token)

  if (status === 200) {
    yield load({ loading: false })
    yield saveItems({ committeesItems: data })
  } yield load({ loading: false })
}

export function * createCommitte ({ payload }) {
  yield load({ loading: true })
  const { eventId, token, data } = payload

  const { status } = yield call(committeService.post, { eventId, data }, token)

  if (status === 201) {
    yield put({
      type: 'event/get_committees',
      payload: { token, eventId }
    })
    message.success('Create Committe Success!')
  } else {
    message.error('Create Committe Failed!')
    yield load({ loading: false })
  }
}

export function * updateCommitte ({ payload }) {
  yield load({ loading: true })
  const { eventId, token, committeId, items } = payload
  const { status } = yield call(committeService.patch, token, { items, eventId, committeId })
  if (status === 202) {
    yield put({
      type: 'event/get_committees',
      payload: { eventId, token }
    })
    message.success('Update Committe Success!')
  } else {
    yield load({ loading: false })
    message.error('Update Committe Failed!!')
  }
}

export function * deleteCommitte ({ payload }) {
  yield load({ loading: true })
  const { committeId, token, eventId } = payload
  const { status } = yield call(committeService.delete, token, { committeId })

  if (status === 202) {
    yield put({
      type: 'event/get_committees',
      payload: { token, eventId }
    })
    message.success('Delete Committe Success!')
  } else {
    message.error('Delete Committe Failed!')
    yield load(true)
  }
}

export function * getSessions ({ payload }) {
  yield load({ loading: true })
  const { token, eventId, visible = false } = payload

  const { status, data: { data } } = yield call(serviceSession.get, token, { eventId })
  if (status === 200) {
    yield saveItems({
      loading: false,
      session: { data, visible }
    })
  } yield load({ loading: false })
}

export function * createSession ({ payload }) {
  yield load({ loading: true })
  const { eventId, token, data } = payload

  const { status, data: items } = yield call(serviceSession.post, token, { eventId, data })
  if (status === 201) {
    yield saveItems({
      loading: false,
      sessionId: items?.data?.id,
      session: {
        step: 1,
        visible: true
      }
    })
    message.success('Created Session Success!')
  } yield load({ loading: false })
}

export function * deleteSession ({ payload }) {
  yield load({ loading: true })
  const { token, sessionId, eventId } = payload

  const response = yield call(serviceSession.delete, token, { sessionId, eventId })

  if (response.status === 202) {
    yield put({
      type: 'event/get_sessions',
      payload: { token, eventId }
    })
    message.success('Delete Session Success!')
  } yield load({ loading: false })
}

export function * getModerator ({ payload }) {
  yield load({ loadingModerator: true })
  const { eventId, token } = payload

  const response = yield call(moderatorService.get, token, { eventId })

  if (response.status === 200) {
    yield saveItems({
      moderator: {
        data: response.data.data
      }
    })
  } yield load({ loadingModerator: false })
}

export function * createModerator ({ payload }) {
  yield load({ loadingModerator: true })
  const { token, eventId, sessionId, newForm: form } = payload

  const data = new FormData()
  data.append('name', form.name)
  data.append('avatar', form.avatar)
  data.append('email', form.email)
  data.append('description', form.description)
  data.append('phone', form.phone)
  form.sessionIds.forEach(id => data.append('sessionIds[]', id))

  const { status } = yield call(moderatorService.post, token, { data, sessionId, eventId })

  if (status === 201) {
    yield put({
      type: 'event/get_moderator',
      payload: {
        token,
        eventId,
        visible: false
      }
    })
    message.success('Created Moderator Success!')
  } yield load({ loadingModerator: false })
}

export function * updateModerator ({ payload }) {
  yield load({ loading: true })
  const { token, eventId, moderatorId, data } = payload

  const response = yield call(moderatorService.patch, token, { moderatorId, data, eventId })

  if (response.status === 202) {
    yield put({
      type: 'event/get_moderator',
      payload: { token, eventId }
    })
    message.success('Update Moderator Success!')
  } yield load({ loading: false })
}

export function * deleteModerator ({ payload }) {
  yield load({ loadingModerator: true })
  const { token, eventId, moderatorId } = payload

  const { status } = yield call(moderatorService.delete, token, { eventId, moderatorId })

  if (status === 202) {
    yield put({
      type: 'event/get_moderator',
      payload: { token, eventId }
    })
    message.success('Delete Moderator Success!')
  } yield load({ loadingModerator: false })
}

export function * getSpeaker ({ payload }) {
  yield load({ loadingSpeaker: true })
  const { eventId, token, visible } = payload

  const response = yield call(speakerService.get, token, { eventId })

  if (response.status === 200) {
    yield saveItems({
      speaker: {
        data: response.data.data,
        visible
      }
    })
  } yield load({ loadingSpeaker: false })
}

export function * createSpeaker ({ payload }) {
  yield load({ loadingSpeaker: true })
  const { token, eventId, newForm: form } = payload

  const data = new FormData()
  data.append('name', form.name)
  data.append('topic', form.topic)
  data.append('description', form.description)
  data.append('avatar', form.avatar)
  data.append('email', form.email)
  data.append('phone', form.phone)
  form.sessionIds.forEach(id => data.append('sessionIds[]', id))

  const response = yield call(speakerService.post, token, { data, eventId })

  if (response.status === 201) {
    yield put({
      type: 'event/get_speaker',
      payload: {
        token,
        eventId,
        visible: false
      }
    })
    message.success('Created Speaker Success!')
  } yield load({ loadingSpeaker: false })
}

export function * updateSpeaker ({ payload }) {
  yield load({ loading: true })
  const { eventId, token, speakerId, data } = payload

  const { status } = yield call(speakerService.patch, token, { eventId, speakerId, data })
  if (status === 202) {
    yield put({
      type: 'event/get_speaker',
      payload: { token, eventId }
    })
    message.success('Update Speaker Success!')
  } yield load({ loading: false })
}

export function * deleteSpeaker ({ payload }) {
  yield load({ loadingSpeaker: true })
  const { token, eventId, speakerId } = payload

  const { status } = yield call(speakerService.delete, token, { eventId, speakerId })

  if (status === 202) {
    yield put({
      type: 'event/get_speaker',
      payload: { token, eventId }
    })
    message.success('Delete Speaker success!')
  } yield load({ loadingSpeaker: true })
}

export function * sessionDone ({ payload }) {
  const { eventId, token } = payload
  yield put({
    type: 'event/get_sessions',
    payload: {
      token,
      eventId,
      visible: false
    }
  })
}

export function * getCertificates ({ payload }) {
  yield load({ loading: true })
  const { token, type } = payload

  const { status, data: { data } } = yield call(serviceCertificate.get, { name: '' }, token)

  if (status === 200) {
    yield saveItems({
      loading: false,
      planCertificates: {
        type,
        visible: true,
        items: data.items,
        meta: data.meta
      }
    })
  }yield load({ loading: false })
}

export function * setPlanCertificate ({ payload }) {
  yield load({ loading: true })
  const { token, type, eventId, plainCertieId } = payload

  const { status } = yield call(serviceCertificate.patch, token, { eventId, data: { plainCertieId, type } })

  if (status === 202) {
    message.success('Success choose design certificate')
    yield saveItems({
      loading: false,
      planCertificates: {
        visible: false
      }
    })
  } yield load({ loading: false })
}

export function * getMember ({ payload }) {
  const { token, params = null } = payload
  const { status, data: { data } } = yield call(memberService.get, token, params)
  if (status === 200) {
    yield saveItems({
      member: {
        data
      }
    })
  } else return null
}

export function * addMember ({ payload }) {
  yield load({ loading: true })
  const { token, eventId, data } = payload
  const { status } = yield call(memberService.post, token, { eventId, data })

  if (status === 201) {
    yield put({
      type: 'event/get_committees',
      payload: { eventId, token }
    })
    message.success('Created Committe Success!')
  } yield load({ loading: false })
}

export function * addMemberParticipant ({ payload }) {
  yield load({ loading: true })
  const { eventId, token, data } = payload

  const { status } = yield call(memberService.postPariticpant, token, { eventId, data })

  if (status === 201) {
    yield put({
      type: 'event/get_participants',
      payload: { eventId, token }
    })
    message.success('Created Participant Success!')
  } yield load({ loading: false })
}

export function * addMmemberModerator ({ payload }) {
  yield load({ loading: true })
  const { eventId, token, data } = payload

  const { status } = yield call(memberService.postModerator, token, { eventId, data })

  if (status === 202) {
    yield put({
      type: 'event/get_moderator',
      payload: { token, eventId }
    })
    message.success('Created Moderator Success!')
  } yield load({ loading: false })
}

export function * addMemberSpeaker ({ payload }) {
  yield load({ loading: true })
  const { eventId, token, data } = payload

  const { status } = yield call(memberService.postSpeaker, token, { eventId, data })
  if (status === 202) {
    yield put({
      type: 'event/get_speaker',
      payload: { token, eventId }
    })
    message.success('Created Speaker Success!')
  } yield load({ loading: false })
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_events, getEvents),
    takeEvery(actions.create_event, createEvent),
    takeEvery(actions.delete_event, deleteEvent),
    takeEvery(actions.update_event, updateEvent),
    takeEvery(actions.create_participant, createParticipant),
    takeEvery(actions.get_participants, getParticipants),
    takeEvery(actions.update_participant, updateParticipant),
    takeEvery(actions.get_committees, getCommittees),
    takeEvery(actions.create_committe, createCommitte),
    takeEvery(actions.update_committe, updateCommitte),
    takeEvery(actions.delete_committe, deleteCommitte),
    takeEvery(actions.delete_participant, deleteParticipant),
    takeEvery(actions.get_sessions, getSessions),
    takeEvery(actions.create_session, createSession),
    takeEvery(actions.delete_session, deleteSession),
    takeEvery(actions.get_moderator, getModerator),
    takeEvery(actions.create_moderator, createModerator),
    takeEvery(actions.update_moderator, updateModerator),
    takeEvery(actions.delete_moderator, deleteModerator),
    takeEvery(actions.get_speaker, getSpeaker),
    takeEvery(actions.delete_speaker, deleteSpeaker),
    takeEvery(actions.create_speaker, createSpeaker),
    takeEvery(actions.update_speaker, updateSpeaker),
    takeEvery(actions.session_done, sessionDone),
    takeEvery(actions.get_certificates, getCertificates),
    takeEvery(actions.set_planCertificate, setPlanCertificate),
    takeEvery(actions.get_member, getMember),
    takeEvery(actions.add_member, addMember),
    takeEvery(actions.add_member_partiicpant, addMemberParticipant),
    takeEvery(actions.add_member_moderator, addMmemberModerator),
    takeEvery(actions.add_member_speaker, addMemberSpeaker),
    takeEvery(actions.get_event_one, getEventOne)
  ])
}
