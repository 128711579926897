import axios from 'axios'
import service from 'src/services/helper/service'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const serviceCertificate = {
  get: (params, token) => service.get('/api/v1/plain-certies', token, params),
  post: (params, token) => service.post('/api/v1/plain-certies', params, token),
  preview: (params, token) => service.post('/api/v1/plain-certies/preview', params, token),
  patch: (token, { eventId, data }) => service.patch(`/api/v1/events/${eventId}/plain-certies`, data, token),
  delete: (id, token) => axios.delete(apiBaseUrl + `/api/v1/plain-certies/${id}`, { headers: { Authorization: `Bearer ${token}` } })
    .catch(err => err.response)
}

export default serviceCertificate
