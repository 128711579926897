import React from 'react'
import { Spin } from 'antd'
import PropTypes from 'prop-types'
import { LoadingOutlined } from '@ant-design/icons'

const CustomLoading = ({ children, loading }) => {
  return (
    <Spin
      className='container-loading-progress'
      spinning={loading}
      indicator={
        <LoadingOutlined
          style={{ fontSize: 40 }}
          spin
        />
    }
    >
      {children}
    </Spin>
  )
}

export default CustomLoading

CustomLoading.propTypes = {
  children: PropTypes.object,
  loading: PropTypes.bool
}
