/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Form,
  Modal,
  Layout,
  Button,
  Divider,
  Row,
  Col,
  Input
} from 'antd'

import {
  UserOutlined,
  LockOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import Logo from 'src/assets/images/icons/logo.png'

import { ILAuth } from 'src/assets/images'
import { rules } from 'src/utils/rules'
import {
  CustomButton,
  CustomGap
} from 'src/components'
import { Link } from 'react-router-dom'
const { Content } = Layout

const Login = ({ dispatch, loading, notice }) => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (notice !== null) setVisible(true)
    else setVisible(false)
  }, [notice])

  const onFinish = () => {
    form.validateFields()
      .then(form => {
        dispatch({
          type: 'auth/sign_in',
          payload: { form }
        })
      })
  }

  const onHandleRedirect = () => {
    dispatch({
      type: 'auth/SET_STATE',
      payload: {
        notice: null
      }
    })
  }

  return (
    <div className='container-login'>
      <div className='card-login'>
        <div className='wrap-login'>
          <div className='login-pic'>
            <img src={Logo} alt='IMG' />
          </div>
          <div className='watermark' />
          <Form
            className='login-form'
            form={form}
            onFinish={onFinish}
          >
            <span className='login-form-title'>
              Member Login
            </span>

            <Form.Item
              name='username'
              className='wrap-input'
              rules={[rules.required]}
            >
              <Input
                prefix={<UserOutlined />}
                className='form-input'
                placeholder='Email'
              />
            </Form.Item>

            <Form.Item
              name='password'
              className='wrap-input'
              rules={[rules.required]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                className='form-input'
                placeholder='Password'
              />
            </Form.Item>
            <CustomGap height='1rem' />
            <Button htmlType='submit' className='form-button-login'>
              Login
            </Button>
          </Form>
        </div>
      </div>
      <Modal
        title={false}
        centered
        visible={visible}
        closable={false}
        footer={false}
        className='notification-message'
      >
        <ExclamationCircleOutlined
          style={{
            fontSize: '4rem',
            color: 'red'
          }}
        />
        <p>
          The username or password incorrect
        </p>
        <div className='text-center mt-3'>
          <Button
            type='primary'
            className='rounded'
            onClick={() => setVisible(!visible)}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = ({ reducerAuth }) => {
  return { ...reducerAuth }
}

export default connect(mapStateToProps)(Login)

Login.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  notice: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
}
