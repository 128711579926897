import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  EditFilled,
  EyeOutlined,
  SyncOutlined,
  DeleteFilled
} from '@ant-design/icons'
import {
  Popconfirm,
  PageHeader,
  Image,
  Button,
  Layout,
  Table,
  Row,
  Space,
  Avatar,
  Popover,
  Divider
} from 'antd'

import {
  CustomGap,
  CustomHeaderMS,
  ModalCreateModerator,
  ModalCreateSpeaker,
  ModalUpdateModerator,
  ModalUpdateSpeaker
} from 'src/components'
import moment from 'moment'

const { Content } = Layout

const StepModeratorSpeaker = ({
  loadingModerator,
  loadingSpeaker,
  moderator,
  dispatch,
  eventId,
  session,
  speaker,
  event,
  token,
  step
}) => {
  const columnsModerator = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: file => <Avatar size={34} icon={<Image src={file} />} />
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone'
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: 'Session',
      key: 'session',
      dataIndex: 'session',
      render: (file, object) => (
        <Popover
          overlayClassName='detail-session'
          title='Session Moderator'
          trigger='click'
          placement='bottomRight'
          content={
            object.sessions.map((item, index) =>
              <Content key={index}>
                <Row align='middle' justify='space-between'>
                  <p className='title'>
                    {item.name}
                  </p>
                  <Divider type='vertical' />
                  <p className='time'>
                    {moment(item.startTime).format('lll')}
                    -
                    {moment(item.endTime).format('LT')}
                  </p>
                </Row>
                <CustomGap height='1rem' />
              </Content>
            )
          }
        >
          <Button
            size='small'
            className='button-detail'
            icon={<EyeOutlined />}
          >
            Detail
          </Button>
        </Popover>
      )
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description'
    },
    {
      title: 'Action',
      align: 'center',
      key: 'action',
      render: (file, items) => (
        <Space size='middle'>
          <Button
            size='small'
            className='button-update'
            icon={<EditFilled />}
            onClick={() => onHandleVisible('update-moderator', items)}
          />
          <Popconfirm
            title='Are you sure delete this moderator?'
            okText='Yes'
            placement='topLeft'
            cancelText='No'
            onConfirm={() => onHandleDelete('moderator', items)}
          >
            <Button
              size='small'
              className='button-delete'
              icon={<DeleteFilled />}
            />
          </Popconfirm>
        </Space>
      )
    }
  ]

  const columnsSpeaker = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: file => <Avatar size={34} icon={<Image src={file} />} />
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone'
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: 'Session',
      key: 'session',
      dataIndex: 'session',
      render: (file, object) => (
        <Popover
          overlayClassName='detail-session'
          title='Session Speaker'
          trigger='click'
          placement='bottomRight'
          content={
            object.sessions.map((item, index) =>
              <Content key={index}>
                <Row align='middle' justify='space-between'>
                  <p className='title'>
                    {item.name}
                  </p>
                  <Divider type='vertical' />
                  <p className='time'>
                    {moment(item.startTime).format('lll')}
                    -
                    {moment(item.endTime).format('LT')}
                  </p>
                </Row>
                <CustomGap height='1rem' />
              </Content>
            )
          }
        >
          <Button
            className='button-detail'
            icon={<EyeOutlined />}
            size='small'
          >
            Detail
          </Button>
        </Popover>
      )
    },
    {
      title: 'Topic',
      key: 'topic',
      dataIndex: 'topic'
    },
    {
      title: 'Action',
      align: 'center',
      key: 'action',
      render: (file, items) => (
        <Space size='middle'>
          <Button
            size='small'
            className='button-update'
            icon={<EditFilled />}
            onClick={() => onHandleVisible('update-speaker', items)}
          />
          <Popconfirm
            title='Are you sure delete this speaker?'
            okText='Yes'
            placement='topLeft'
            cancelText='No'
            onConfirm={() => onHandleDelete('speaker', items)}
          >
            <Button
              danger
              size='small'
              className='button-delete'
              icon={<DeleteFilled />}
            />
          </Popconfirm>
        </Space>
      )
    }
  ]

  useEffect(() => {
    dispatch({
      type: 'event/get_sessions',
      payload: { token, eventId }
    })
  }, [])

  useEffect(() => {
    dispatch({
      type: 'event/get_moderator',
      payload: { token, eventId }
    })
    dispatch({
      type: 'event/get_speaker',
      payload: { token, eventId }
    })
  }, [])

  const setDispatch = (payload) => {
    dispatch({
      type: 'event/set_state',
      payload
    })
  }

  const onHandleVisible = (type, items) => {
    switch (type) {
      case 'update-moderator':
        return setDispatch({
          update: {
            visibleModerator: true,
            moderator: items
          }
        })
      case 'update-speaker':
        return setDispatch({
          update: {
            visibleSpeaker: true,
            speaker: items
          }
        })
      default:
        return dispatch({
          type: 'event/get_sessions',
          payload: {
            token, eventId
          }
        })
    }
  }

  const onHandleDelete = (type, { id, name }) => {
    switch (type) {
      case 'moderator':
        return dispatch({
          type: 'event/delete_moderator',
          payload: {
            moderatorId: id,
            token,
            eventId
          }
        })
      case 'speaker':
        return dispatch({
          type: 'event/delete_speaker',
          payload: {
            speakerId: id,
            token,
            eventId
          }
        })
      default:
        return null
    }
  }

  const onHandleStep = (type) => {
    switch (type) {
      case 'next':
        if (event) {
          return setDispatch({ update: { step: 4, event } })
        } else return setDispatch({ step: step + 1, session: { data: session.data } })
      case 'previous':
        if (event) {
          return setDispatch({ update: { step: 2, event } })
        } else return setDispatch({ step: step - 1, session: { data: session.data } })
      default:
        return false
    }
  }

  return (
    <>
      <Content className='container-create-ms'>
        <CustomHeaderMS token={token} list={session?.data} />
        <CustomGap height='1rem' />
        <Table
          size='small'
          rowKey='id'
          columns={columnsModerator}
          loading={loadingModerator}
          dataSource={moderator?.data?.items}
        />
        <PageHeader
          ghost={false}
          subTitle={[
            <p key='1' className='big-title'>
              Speaker
            </p>
          ]}
        />
        <Table
          size='small'
          rowKey='id'
          rowClassName='table-sessions'
          columns={columnsSpeaker}
          loading={loadingSpeaker}
          dataSource={speaker?.data?.items}
        />
        <Row
          justify='space-between'
          gutter={16}
          className='form-footer'
        >
          <Button icon={<SyncOutlined />}>
            Reset
          </Button>
          <Space justify='end'>
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => onHandleStep('previous')}
            >
              Previous
            </Button>
            <Button
              type='primary'
              icon={<ArrowRightOutlined />}
              onClick={() => onHandleStep('next')}
            />
          </Space>
        </Row>
      </Content>
      <ModalCreateSpeaker token={token} list={session?.data} />
      <ModalCreateModerator token={token} list={session?.data} />
      <ModalUpdateModerator token={token} list={session?.data} />
      <ModalUpdateSpeaker token={token} list={session?.data} />
    </>
  )
}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(StepModeratorSpeaker)

StepModeratorSpeaker.propTypes = {
  dispatch: PropTypes.func,
  event: PropTypes.object,
  token: PropTypes.string,
  session: PropTypes.object,
  eventId: PropTypes.string,
  moderator: PropTypes.object,
  speaker: PropTypes.object,
  loadingModerator: PropTypes.bool,
  loadingSpeaker: PropTypes.bool,
  step: PropTypes.number
}
