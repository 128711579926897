import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import { reducerAuth } from './Auth/reducers'
import { reducerEvent } from './Events/reducers'
import { reducerCertificate } from './Certificate/reducers'
import { reducerUtils } from './Utils/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    reducerAuth,
    reducerEvent,
    reducerCertificate,
    reducerUtils
  })
