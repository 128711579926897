import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import {
  Menu,
  Modal,
  Button,
  Dropdown,
  PageHeader,
  Avatar,
  Image,
  Form,
  Select,
  Divider,
  Input,
  Row
} from 'antd'
import {
  PlusCircleOutlined,
  SelectOutlined,
  DiffOutlined
} from '@ant-design/icons'

import { rules } from 'src/utils/rules'
const Text = ({ children }) => <p style={{ margin: 0 }}>{children}</p>
const { Option } = Select

const CustomHeaderMS = ({
  moderator,
  loading,
  dispatch,
  eventId,
  member,
  speaker,
  token,
  list
}) => {
  const [formMember] = Form.useForm()
  const [visibleMember, setVisibleMember] = useState({
    visible: false,
    moderator: false,
    speaker: false
  })

  const menu = (type) => {
    return (
      <Menu>
        <Menu.Item
          key='1'
          icon={<SelectOutlined />}
          onClick={() => onHandleVisible(type, 'select')}
        >
          Select from others
        </Menu.Item>
        <Menu.Item
          key='2'
          icon={<DiffOutlined />}
          onClick={() => onHandleVisible(type, 'create')}
        >
          Create New
        </Menu.Item>
      </Menu>
    )
  }

  const setDispatch = (payload) => {
    dispatch({
      type: 'event/set_state',
      payload
    })
  }

  const onHandleVisible = (type, menu) => {
    if (type === 'moderator') {
      if (menu === 'select') { setVisibleMember({ visible: true, moderator: true, speaker: false }) }
      if (menu === 'create') setDispatch({ moderator: { visible: true, data: moderator.data } })
    }
    if (type === 'speaker') {
      if (menu === 'select') setVisibleMember({ visible: true, speaker: true, moderator: false })
      if (menu === 'create') setDispatch({ speaker: { visible: true, data: speaker.data } })
    } else return false
  }

  const onHandleClose = () => {
    setVisibleMember(false)
    formMember.resetFields()
  }

  const onHandleSubmit = () => {
    if (visibleMember.moderator) {
      formMember.validateFields().then(data => {
        dispatch({
          type: 'event/add_member_moderator',
          payload: {
            token, eventId, data
          }
        })
      })
    }
    if (visibleMember.speaker) {
      formMember.validateFields().then(data => {
        dispatch({
          type: 'event/add_member_speaker',
          payload: {
            token, eventId, data
          }
        })
      })
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        subTitle={[<p key='1' className='big-title'>Moderator</p>]}
        extra={[
          <Dropdown
            key='1'
            overlay={menu('moderator')}
            trigger={['click']}
          >
            <Button
              key='1'
              type='primary'
              icon={<PlusCircleOutlined />}
            >
              Create Moderator
            </Button>
          </Dropdown>,
          <Dropdown
            key='3'
            overlay={menu('speaker')}
            trigger={['click']}
          >
            <Button
              key='2'
              type='primary'
              icon={<PlusCircleOutlined />}
            >
              Create Speaker
            </Button>
          </Dropdown>
        ]}
      />
      <Modal
        title='Choose Member'
        onCancel={onHandleClose}
        footer={false}
        visible={visibleMember.visible}
      >
        <Form
          layout='vertical'
          form={formMember}
          onFinish={onHandleSubmit}
        >
          <Form.Item
            name='username'
            label='Member'
            rules={[rules.required]}
          >
            <Select
              defaultValue=''
              placeholder='Select a person'
              optionFilterProp='children'
              size='large'
            >
              {member?.data?.items
                ?.map((item, index) =>
                  <Select.Option
                    key={index}
                    value={item?.username}
                  >
                    <Avatar size={30} icon={<Image preview={false} src={item?.avatar} />} />
                    <Divider type='vertical' />
                    {item?.fullName}
                  </Select.Option>
                )}
            </Select>
          </Form.Item>
          <Form.Item
            label='Select Session'
            name='sessionIds'
            rules={[rules.required]}
          >
            <Select
              mode='multiple'
              size='large'
              optionLabelProp='label'
              placeholder='Please Select Session'
              style={{ width: '100%' }}
            >
              {list?.items?.map((item, index) =>
                <Option
                  key={index}
                  value={item.id}
                  label={item.name}
                >
                  <Row justify='space-between' align='middle'>
                    <Row align='middle'>
                      <a href='#'>
                        {item.name}
                      </a>
                    </Row>
                    <Row align='middle'>
                      <Text>
                        {moment(item.startTime).format('ll')}{' '}
                      </Text>
                      <Divider type='vertical' />
                      <Text>
                        {moment(item.startTime).format('LT')}
                      </Text>
                      <Divider type='vertical' />
                      <Text>
                        {moment(item.endTime).format('LT')}
                      </Text>
                    </Row>
                  </Row>
                </Option>
              )}
            </Select>
          </Form.Item>
          {!visibleMember.moderator && (
            <Form.Item name='topic' label='Topic' rules={[rules.required]}>
              <Input size='large' />
            </Form.Item>
          )}
          <Form.Item name='description' label='Description'>
            <Input.TextArea size='large' />
          </Form.Item>
          <Row justify='end'>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              icon={<PlusCircleOutlined />}
            >
              Create {visibleMember.moderator ? 'Moderator' : 'Speaker'}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(CustomHeaderMS)

CustomHeaderMS.propTypes = {
  dispatch: PropTypes.func,
  list: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  token: PropTypes.string,
  eventId: PropTypes.string,
  moderator: PropTypes.object,
  speaker: PropTypes.object,
  loading: PropTypes.bool,
  member: PropTypes.object
}

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ])
}
