const actions = {
  set_step: 'event/set_step',
  set_state: 'event/set_state',
  get_events: 'event/get_events',
  create_event: 'event/create_event',
  update_event: 'event/update_event',
  delete_event: 'event/delete_event',
  get_participants: 'event/get_participants',
  create_participant: 'event/create_participant',
  delete_participant: 'event/delete_participant',
  update_participant: 'event/update_participant',
  get_committees: 'event/get_committees',
  create_committe: 'event/create_committe',
  update_committe: 'event/update_committe',
  delete_committe: 'event/delete_committe',
  get_sessions: 'event/get_sessions',
  create_session: 'event/create_session',
  delete_session: 'event/delete_session',
  get_moderator: 'event/get_moderator',
  create_moderator: 'event/create_moderator',
  update_moderator: 'event/update_moderator',
  delete_moderator: 'event/delete_moderator',
  get_speaker: 'event/get_speaker',
  create_speaker: 'event/create_speaker',
  update_speaker: 'event/update_speaker',
  delete_speaker: 'event/delete_speaker',
  session_done: 'event/session_done',
  get_certificates: 'event/get_certificates',
  set_planCertificate: 'event/set_planCertificate',
  get_member: 'event/get_member',
  add_member: 'event/add_member',
  add_member_partiicpant: 'event/add_member_participant',
  add_member_moderator: 'event/add_member_moderator',
  add_member_speaker: 'event/add_member_speaker',
  get_event_one: 'event/get_event_one'
}

export { actions }
