import axios from 'axios'
import service from 'src/services/helper/service'
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const serviceEvent = {
  get: (params, token) => service.get('/api/v1/events', token, params),
  getOne: (token, eventId) => service.get(`/api/v1/events/${eventId}`, token, null),
  post: (token, { data }) => service.post('/api/v1/events', data, token),
  patch: (token, { eventId, data }) => service.patch(`/api/v1/events/${eventId}`, data, token),
  delete: (eventId, token) => axios.delete(apiBaseUrl + `/api/v1/events/${eventId}`,
    { headers: { Authorization: `Bearer ${token}` } })
    .catch(err => err.response)
}

export default serviceEvent
