import React, { useState } from 'react'
import {
  Row,
  Layout,
  Button,
  PageHeader,
  Table,
  Checkbox
} from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import CustomLayout from 'src/layout/dashboard'
import { CustomGap } from 'src/components'

const dummyEventStatus = [
  {
    name: 'Diko Mahendra',
    status: 'completed',
    count: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    absensi: {
      count: 2
    }
  },
  {
    name: 'Jhon Doe',
    status: 'uncompleted',
    count: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    absensi: {
      count: 2
    }
  },
  {
    name: 'Ahmad Hermawan',
    status: 'completed',
    count: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    absensi: {
      count: 3
    }
  },
  {
    name: 'Cika Lorita',
    status: 'completed',
    count: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    absensi: {
      count: 8
    }
  },
  {
    name: 'Reza Ahmad Fikri',
    status: 'uncompleted',
    count: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    absensi: {
      count: 5
    }
  }
]

const CertificateEvent = () => {
  const [checklist, setChecklist] = useState(false)
  const dataSource = [
    {
      key: '1',
      name: 'Diko Mahendra'
    },
    {
      key: '2',
      name: 'Jhone Doe'
    },
    {
      key: '3',
      name: 'Ahmad Hermawan'
    },
    {
      key: '4',
      name: 'Cika Lorita'
    },
    {
      key: '5',
      name: 'Julia Sinia'
    }
  ]

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: '1',
      width: 300
    },
    {
      title: 'Status',
      dataIndex: 'action',
      align: 'center',
      key: '3',
      render: (id) => (
        <Button
          type='primary'
          className='rounded'
          icon={<CheckCircleFilled />}
        >
          Completed
        </Button>
      )
    },
    {
      title: 'Absensi Session',
      dataIndex: 'absensi',
      key: '2',
      width: 500,
      render: file => (
        <Row>
          {dummyEventStatus.map((item, key) =>
            <Checkbox defaultChecked value='A' key={key} />
          )}
        </Row>
      )
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name
    })
  }

  return (
    <CustomLayout>
      <Layout className='container-certificate-event'>
        <PageHeader
          ghost={false}
          title={[
            <Row key='23' align='middle'>
              <CustomGap width='1rem' />
              <Row>
                <Checkbox checked={checklist} value='A' />
                <Checkbox checked={checklist} value='A' />
                <Checkbox checked={checklist} value='A' />
                <Checkbox checked={checklist} value='A' />
                <Checkbox checked={checklist} value='A' />
              </Row>
            </Row>
          ]}
          extra={[
            <Row key='button-right'>
              <Button type='primary' className='button-publish rounded-pill'>
                Publish Certificate
              </Button>
              <CustomGap width='5px' />
              <Button
                type='primary'
                className='button-checklist rounded-pill'
                onClick={() => setChecklist(!checklist)}
              >
                Checklist All
              </Button>
            </Row>
          ]}
        />
        <CustomGap height='2rem' />
        <Layout.Content>
          <Table
            rowSelection={{
              type: 'checkbox',
              ...rowSelection
            }}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </Layout.Content>
      </Layout>
    </CustomLayout>
  )
}

export default CertificateEvent
