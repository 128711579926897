import service from 'src/services/helper/service'

const memberService = {
  get: (token, params) => service.get('/api/v1/users/cursor', token, { params }),
  post: (token, { eventId, data }) => service.post(`/api/v1/events/${eventId}/committees/add-member`, data, token),
  postPariticpant: (token, { eventId, data }) => service.post(`/api/v1/events/${eventId}/hosts/add-member`, data, token),
  postModerator: (token, { eventId, data }) => service.post(`/api/v1/events/${eventId}/moderators/create-by-username`, data, token),
  postSpeaker: (token, { eventId, data }) => service.post(`/api/v1/events/${eventId}/speakers/create-by-username`, data, token)
}

export default memberService
