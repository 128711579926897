import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  Image,
  Col,
  Row,
  Input,
  Menu,
  Layout,
  Popover,
  PageHeader
} from 'antd'
import {
  SearchOutlined,
  IdcardTwoTone,
  DeleteTwoTone,
  MoreOutlined,
  EditTwoTone,
  EyeTwoTone
} from '@ant-design/icons'

import CustomLayout from 'src/layout/dashboard'
import { CustomEmpty, CustomGap, CustomLoading } from 'src/components'
import { Link } from 'react-router-dom'

const { Content } = Layout

const Event = ({
  dispatch,
  loading,
  token,
  step,
  data
}) => {
  const [searchBy, setSearchByName] = useState('')

  const onHandleRemoveEvent = eventId => {
    dispatch({
      type: 'event/delete_event',
      payload: { eventId, token }
    })
  }

  const popover = (eventId) => (
    <Menu defaultSelectedKeys={['0']}>
      <Menu.Item key='preview' icon={<EyeTwoTone />}>
        <Link to={`/list-event/detail/${eventId}`}>
          Preview
        </Link>
      </Menu.Item>
      <Menu.Item key='certificate' icon={<IdcardTwoTone />}>
        <Link to={`/list-event/certificate/${eventId}`}>
          Certificate
        </Link>
      </Menu.Item>
      <Menu.Item key='edit' icon={<EditTwoTone />}>
        <Link to={`/list-event/${eventId}`}>
          Edit
        </Link>
      </Menu.Item>
      <Menu.Item
        key='delete'
        icon={<DeleteTwoTone />}
        onClick={() => onHandleRemoveEvent(eventId)}
      >
        Delete
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    dispatch({
      type: 'event/get_events',
      payload: {
        token,
        searchBy
      }
    })
  }, [searchBy])

  return (
    <CustomLayout>
      <Layout className='container-list-events'>
        <PageHeader className='header-bar'>
          <Input
            prefix={<SearchOutlined />}
            allowClear
            size='large'
            placeholder='search event by session name, moderator name, or event name'
            onChange={({ target: { value } }) => setSearchByName(value)}
          />
        </PageHeader>
        <CustomGap height='1rem' />
        <Row className='wrapper-card'>
          {data?.items?.map(({ id, banner, description, type, name }, key) =>
            <Col key={key} span={24}>
              <Card hoverable>
                <Row justify='space-between' align='middle'>
                  <Image src={banner} className='image-banner' />
                  <Content className='wrapper-card-description'>
                    <p className='title'>
                      {name}
                    </p>
                    <p className='description'>
                      {description.length > 70 ? description.substring(0, 70) + '...' : description}
                    </p>
                    <Row align='middle'>
                      <p className='count'>
                        10/2{key}
                      </p>
                      <CustomGap width='1rem' />
                      <p className='event'>
                        {type}
                      </p>
                    </Row>
                  </Content>
                  <Popover
                    overlayClassName='popover-content'
                    placement='leftTop'
                    trigger='click'
                    content={popover(id)}
                  >
                    <Button>
                      <MoreOutlined />
                    </Button>
                  </Popover>
                </Row>
              </Card>
            </Col>
          )}
          {loading && <CustomLoading loading={loading} />}
        </Row>
        {data?.items?.length !== 0 ? null : <CustomEmpty type='event' />}
      </Layout>
    </CustomLayout>
  )
}

Event.propTypes = {}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(Event)

Event.propTypes = {
  data: PropTypes.object,
  step: PropTypes.number,
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  token: PropTypes.string
}
