import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  PageHeader,
  Popconfirm,
  Layout,
  Button,
  Space,
  Table,
  Row
} from 'antd'
import {
  SyncOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusCircleOutlined,
  DeleteFilled,
  EditFilled
} from '@ant-design/icons'
import { ModalCreateSession } from 'src/components'

const { Content } = Layout
const StepSession = ({
  step,
  token,
  event,
  eventId,
  loading,
  dispatch,
  session
}) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, object) => text.length > 30 ? <a>{text.substr(0, 30) + '...'}</a> : <a>{text}</a>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: text => text.length > 50 ? text.substr(0, 50) + '...' : text
    },
    {
      title: 'Start Time',
      key: 'startTime',
      dataIndex: 'startTime',
      render: (file, { startTime }) => moment(startTime).format('LLL')
    },
    {
      title: 'End Time',
      key: 'endTime',
      dataIndex: 'endTime',
      render: (file, { endTime }) => moment(endTime).format('LLL')
    },
    {
      title: 'Action',
      align: 'center',
      key: 'action',
      render: (file, { id }) => (
        <Space size='middle'>
          <Button className='button-update' icon={<EditFilled />}>
            Update
          </Button>
          <Popconfirm
            title='Are you sure delete this session?'
            okText='Yes'
            placement='topLeft'
            cancelText='No'
            onConfirm={() => onHandleDelete(id)}
          >
            <Button
              className='button-delete'
              icon={<DeleteFilled />}
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ]

  useEffect(() => {
    if (!session.visible) {
      dispatch({
        type: 'event/get_sessions',
        payload: { token, eventId }
      })
    }
  }, [session.visible])

  const onHandleDelete = (sessionId) => {
    dispatch({
      type: 'event/delete_session',
      payload: { token, sessionId, eventId }
    })
  }

  const onHandleVisible = (visible) => {
    dispatch({
      type: 'event/set_state',
      payload: {
        session: { data: session.data, visible }
      }
    })
  }

  const setSaveState = (payload) => dispatch({
    type: 'event/set_state',
    payload
  })

  const onHandleStep = (type) => {
    switch (type) {
      case 'previous':
        if (event) {
          return setSaveState({ update: { step: 1, event } })
        } else return setSaveState({ step: step - 1, session: { data: session.data } })
      case 'next':
        if (event) {
          return setSaveState({ update: { step: 3, event } })
        } else return setSaveState({ step: step + 1, session: { data: session.data } })
      default:
        return false
    }
  }

  return (
    <>
      <Content className='container-create-session'>
        <PageHeader
          ghost={false}
          subTitle={[<p key='1' className='big-title'>Create Session</p>]}
          extra={[
            <Button
              key='1'
              type='primary'
              icon={<PlusCircleOutlined />}
              onClick={() => onHandleVisible(true)}
            >
              Create Sessions
            </Button>
          ]}
        />
        <Table
          rowKey='id'
          columns={columns}
          loading={loading}
          dataSource={session.data?.items}
        />
        <Row
          justify='space-between'
          gutter={16}
          className='form-footer'
        >
          <Button icon={<SyncOutlined />}>
            Reset
          </Button>
          <Space justify='end'>
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => onHandleStep('previous')}
            >
              Previous
            </Button>
            <Button
              type='primary'
              icon={<ArrowRightOutlined />}
              onClick={() => onHandleStep('next')}
            />
          </Space>
        </Row>
      </Content>

      <ModalCreateSession token={token} />
    </>
  )
}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(StepSession)

StepSession.propTypes = {
  event: PropTypes.object,
  step: PropTypes.number,
  session: PropTypes.object,
  loading: PropTypes.bool,
  token: PropTypes.string,
  eventId: PropTypes.string,
  dispatch: PropTypes.func
}
