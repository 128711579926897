import React, { useState } from 'react'
import { Image, Menu, Divider, Row, Layout } from 'antd'
import { Link } from 'react-router-dom'

import {
  ReconciliationFilled,
  InteractionFilled,
  PlusSquareFilled,
  ContainerFilled,
  AppstoreFilled,
  PrinterFilled,
  MenuUnfoldOutlined
} from '@ant-design/icons'
import Sider from 'antd/lib/layout/Sider'

import { ILAvatarUser } from 'src/assets/images'
const { SubMenu } = Menu
const { Content } = Layout

export const CustomSidebar = () => {
  const [collapsed, setCollapsed] = useState(false)

  const toggle = () => {
    setCollapsed({
      collapsed: !collapsed
    })
  }

  return (
    <Sider
      className='dashboard-siderbar'
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        background: '#003366',
        left: 0
      }}
    >
      <Row className='header-sidebar' align='middle'>
        <MenuUnfoldOutlined onClick={() => toggle()} />
        <p className='title-bar'>Certificates</p>
      </Row>
      <Menu mode='vertical' className='sidebar-menu' defaultSelectedKeys={['4']}>
        <Content className='container-component--profile'>
          <Row align='middle'>
            <Image
              src={ILAvatarUser}
              preview={false}
              className='image-profile'
              alt='profile'
            />
            <p className='profile-name'>
              Diko Mahendra
            </p>
          </Row>
          <Divider />
        </Content>
        <Menu.Item key='dashboard' icon={<AppstoreFilled />}>
          <Link to='/'>Dashboard</Link>
        </Menu.Item>
        <SubMenu
          key='list-event'
          title='Events'
          icon={<ReconciliationFilled />}
        >
          <Menu.Item key='5' icon={<ReconciliationFilled />}>
            <Link to='/list-event'>
              List Events
            </Link>
          </Menu.Item>
          <Menu.Item key='create-event' icon={<PlusSquareFilled />}>
            <Link to='/create-event'>
              Create Event
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key='certificates' icon={<ContainerFilled />}>
          <Link to='/certificates'>
            Design Certificates
          </Link>
        </Menu.Item>
        <Menu.Item key='products' icon={<InteractionFilled />}>
          <Link to='/products'>
            Products
          </Link>
        </Menu.Item>
        <Menu.Item key='qr-code' icon={<PrinterFilled />}>
          <Link to='/qr-code'>
            QR - Code
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  )
}
