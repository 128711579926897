import {
  ICAlignCenterStyle,
  ICAlignLeftStyle,
  ICAlignRightStyle,
  ICBoldStyle,
  ICCopyPasteStyle,
  ICItalicStyle,
  ICNextStyle,
  ICPrevStyle,
  ICTrashStyle,
  ICUnderlineStyle
} from 'src/assets/images'

export const iconStyle = [
  ICBoldStyle,
  ICUnderlineStyle,
  ICItalicStyle,
  ICAlignLeftStyle,
  ICAlignCenterStyle,
  ICAlignRightStyle,
  ICTrashStyle,
  ICCopyPasteStyle,
  ICPrevStyle,
  ICNextStyle
]
export const menu = [
  'Documents',
  'Templates',
  'Texts',
  'Elements'
]

export const fontSize = [10, 11, 12, 13, 14, 18, 20, 22, 24, 36, 48, 64, 72]

export const fontFamily = [
  'Oswald',
  'Lobster',
  'Fontdiner Swanky',
  'Gloria Hallelujah',
  'Crafty Girls',
  'Pacifico',
  'Satisfy',
  'Bangers',
  'Audiowide',
  'Sacramento']

export const paperSize = ['A4', 'US Latter']

export const orientation = ['Portrait', 'Landscape']

export const shapeElements = [
  'element-rectangle',
  'element-triangle',
  'element-circle',
  'element-polygon',
  'element-star',
  'element-arrow-left',
  'element-chevron-right',
  'element-closed'
]
