import React from 'react'
import PropTypes from 'prop-types'
import { Layout, Row, Image, Space, Button } from 'antd'
import { InteractionOutlined, ContainerOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import ILFinished from 'src/assets/images/illustration/done.png'
import { CustomGap } from 'src/components'
import { connect } from 'react-redux'

const { Content } = Layout
const CustomFinished = ({ dispatch, event }) => {
  const handleCreateNewEvent = () => {
    dispatch({
      type: 'event/set_state',
      payload: {
        step: 0,
        update: { step: 0, event }
      }
    })
  }

  return (
    <Content className='container-well-done'>
      <Row justify='center'>
        <Image src={ILFinished} preview={false} />
      </Row>
      <Content className='content-typewriter'>
        <p className='title'>
          Created Event Successfully
        </p>
        <p className='subtitle'>
          You can create a new event,
          or checking your event successfully created
        </p>
      </Content>
      <Row justify='center'>
        <Space>
          <Button icon={<InteractionOutlined />} onClick={handleCreateNewEvent}>
            Create New Event
          </Button>
          <Link to='/list-event'>
            <Button
              type='primary'
              onClick={handleCreateNewEvent}
              icon={<ContainerOutlined />}
            >
              Check Event
            </Button>
          </Link>
        </Space>
      </Row>
      <CustomGap height='2rem' />
    </Content>
  )
}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(CustomFinished)

CustomFinished.propTypes = {
  dispatch: PropTypes.func,
  event: PropTypes.object
}
