import React from 'react'
import PropTypes from 'prop-types'

const CustomGap = ({
  height,
  width,
  color
}) => {
  return (
    <div style={{
      width: width,
      height: height,
      backgroundColor: color
    }}
    />
  )
}

export default CustomGap

CustomGap.propTypes = {
  height: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string
}
