import React, { useState } from 'react'
import {
  DatePicker,
  Layout,
  Button,
  Upload,
  Input,
  Form,
  Row,
  Col,
  Modal,
  Result
} from 'antd'
import PropTypes from 'prop-types'
import {
  CameraOutlined,
  ClusterOutlined,
  PlusCircleOutlined
} from '@ant-design/icons'

import LayoutDashboard from 'src/layout/dashboard'
import { CustomGap } from 'src/components'
import { Link } from 'react-router-dom'

const { Content } = Layout

const CustomFIeld = ({
  col,
  name,
  label,
  placeholder,
  datepicker,
  textarea
}) => (
  <Col span={col || 24}>
    <Form.Item name={name} label={label}>
      {
        datepicker
          ? <DatePicker size='large' style={{ width: '100%' }} />
          : textarea
            ? <Input.TextArea placeholder={placeholder} />
            : !datepicker && !textarea
                ? <Input size='large' placeholder={placeholder} />
                : <Input size='large' placeholder={placeholder} />
      }
    </Form.Item>
  </Col>
)

export const CreateProduct = () => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)

  const onHandleCreate = () => {
    setVisible(!visible)
  }

  return (
    <LayoutDashboard>
      <Layout className='container-create-products'>
        <Content className='form-create-product'>
          <span className='title'>
            Create a Product
          </span>
          <CustomGap height='2rem' />
          <Form layout='vertical' form={form}>
            <Row gutter={16}>
              <CustomFIeld
                col={12}
                name='name'
                placeholder='input your product name'
                label='Name Product'
              />
              <CustomFIeld
                col={12}
                name='quality'
                placeholder='input your quality of product'
                label='Quality'
              />
            </Row>
            <Row gutter={16}>
              <CustomFIeld
                col={12}
                name='startDate'
                placeholder='input your date production'
                label='Date of Production'
                datepicker
              />
              <CustomFIeld
                col={12}
                name='limit'
                placeholder='input your limit checking'
                label='Limit for Checking'
              />
            </Row>
            <Row gutter={16}>
              <CustomFIeld
                col={12}
                name='expired'
                placeholder='input your product expired'
                label='Product Expired'
                datepicker
              />
              <CustomFIeld
                col={12}
                name='description'
                placeholder='input your description'
                label='Description' textarea
              />
            </Row>

            <Form.Item label='Upload Thumbnail'>
              <Col span={24} name='img_thumbnail'>
                <Upload
                  name='avatar'
                  listType='picture-card'
                  action={false}
                  className='avatar-uploader'
                >
                  <CameraOutlined />
                </Upload>
              </Col>
            </Form.Item>
            <Row justify='end'>
              <Button onClick={onHandleCreate}>
                Create
              </Button>
            </Row>
          </Form>
        </Content>
        <Modal
          footer={false}
          visible={visible}
          centered
          maskClosable={false}
          closable={false}
          wrapClassName='create-products'
        >
          <Result
            status='success'
            title='Success Created'
            subTitle={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'}
            extra={[
              <Button
                type='primary'
                key='craete'
                onClick={onHandleCreate}
                icon={<PlusCircleOutlined />}
              >
                Create New
              </Button>,
              <Link to='/products' key='checking'>
                <Button key='buy' icon={<ClusterOutlined />}>
                  Check Products
                </Button>
              </Link>
            ]}
          />
        </Modal>
      </Layout>
    </LayoutDashboard>
  )
}

CustomFIeld.propTypes = {
  col: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  datepicker: PropTypes.bool,
  textarea: PropTypes.bool
}
