/* eslint-disable no-unused-vars */
import React from 'react'
import {
  Menu,
  Layout,
  Button,
  Popover,
  Table
} from 'antd'
import {
  SmallDashOutlined,
  PrinterOutlined,
  DeleteOutlined,
  EditOutlined,
  QrcodeOutlined
} from '@ant-design/icons'

import CustomLayout from 'src/layout/dashboard'
import { CustomHeader } from 'src/components'

const ListProducts = () => {
  const columns = [
    {
      title: 'Name Product',
      dataIndex: 'name',
      render: (text) => <a>{text}</a>
    },
    {
      title: 'Date',
      dataIndex: 'date'
    },
    {
      title: 'Expired',
      dataIndex: 'expired'
    },
    {
      title: 'Quantitas',
      dataIndex: 'quantitas'
    },
    {
      title: 'QRCode',
      dataIndex: 'qrcode'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: () => (
        <Popover
          key='action'
          content={actions}
          placement='left'
          trigger='click'
          overlayClassName='popover-product'
        >
          <Button>
            <SmallDashOutlined />
          </Button>
        </Popover>
      )
    }
  ]

  const data = [
    {
      key: '1',
      name: 'John Brown',
      date: '12/23/2021',
      expired: '12/02/2020',
      quantitas: '200 unit',
      qrcode: 'ungenerate'
    }
  ]

  const actions = () => (
    <Menu key='action'>
      <Menu.Item key='update' icon={<EditOutlined />}>
        Update
      </Menu.Item>
      <Menu.Item key='download' icon={<QrcodeOutlined />}>
        Download QR
      </Menu.Item>
      <Menu.Item key='print' icon={<PrinterOutlined />}>
        Print QR
      </Menu.Item>
      <Menu.Item key='delete' icon={<DeleteOutlined />}>
        Delete
      </Menu.Item>
    </Menu>
  )

  return (
    <CustomLayout>
      <Layout className='container-products'>
        <CustomHeader simple />
        <Table
          rowSelection={{
            type: 'checkbox'
          }}
          columns={columns}
          dataSource={data}
        />
      </Layout>
    </CustomLayout>
  )
}

export default ListProducts

ListProducts.propTypes = {}
