import { actions } from './actions'

const initalState = {
  loading: false,
  menu: null,
  type: null,
  mode: null,
  fontFamily: null,
  list: [],
  template: null
}

const reducerCertificate = (state = initalState, action) => {
  switch (action.type) {
    case actions.state:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export { reducerCertificate }
