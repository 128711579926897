import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  DatePicker,
  Button,
  Upload,
  Layout,
  Input,
  Form,
  Col,
  Row,
  Space
} from 'antd'
import {
  RetweetOutlined,
  UploadOutlined,
  CameraOutlined
} from '@ant-design/icons'

import { rules } from 'src/utils/rules'
import { connect } from 'react-redux'
import moment from 'moment'

const { TextArea } = Input
const { Content } = Layout

const CustomField = ({
  children,
  label,
  date,
  name,
  placeholder,
  value,
  onChange,
  description
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={[rules.required]}
  >
    {date
      ? children
      : description
        ? <TextArea size='large' value={value} onChange={onChange} />
        : <Input
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            size='large'
          />}
  </Form.Item>
)

const StepEvent = ({ event, step, dispatch, token, loading }) => {
  const [form] = Form.useForm()
  const [fieldValue, setFieldValue] = useState({})
  const [datePicker, setDatePicker] = useState({
    endDate: null,
    startDate: null
  })

  const props = {
    name: 'file',
    listType: 'picture-card',
    beforeUpload: file => {}
  }

  useEffect(() => {
    if (event) {
      setFieldValue({ ...event })
      setDatePicker({
        startDate: event.startDate,
        endDate: event.endDate
      })
    }
  }, [event])

  const onHandleSubmit = () => {
    if (!event) {
      form.validateFields().then((form) => {
        const data = {
          ...form,
          ...datePicker,
          startDate: new Date(datePicker.startDate).toISOString(),
          endDate: new Date(datePicker.endDate).toISOString(),
          organizerImage: form.organizerImage.fileList[0].originFileObj,
          banner: form.banner.fileList[0].originFileObj
        }

        dispatch({
          type: 'event/create_event',
          payload: {
            data,
            token,
            step
          }
        })
      })
    } else {
      const data = {
        name: fieldValue?.name,
        publisher: fieldValue?.publisher,
        type: fieldValue?.type,
        startDate: new Date(datePicker?.startDate).toISOString(),
        endDate: new Date(datePicker?.endDate).toISOString(),
        organizerName: fieldValue?.organizer?.name,
        description: fieldValue?.description
      }

      dispatch({
        type: 'event/update_event',
        payload: {
          eventId: fieldValue?.id,
          data,
          token
        }
      })
    }
  }

  const onHandleStep = () => {
    dispatch({
      type: 'event/set_state',
      payload: {
        update: { step: 1, event }
      }
    })
  }

  return (
    <Content>
      <Form
        form={form}
        layout='vertical'
        autoComplete='off'
        requiredMark={false}
        onFinish={onHandleSubmit}
      >
        <Row gutter={16}>
          <Col span={24} md={12}>
            <CustomField
              name={event ? null : 'name'}
              value={event ? fieldValue?.name : null}
              placeholder='your event name'
              label='Event Name'
              onChange={({ target }) => setFieldValue({
                ...fieldValue,
                name: target.value
              })}
            />
          </Col>
          <Col span={24} md={12}>
            <CustomField
              name={event ? null : 'type'}
              value={event ? fieldValue?.type : null}
              label='Event Type'
              placeholder='event type'
              onChange={({ target }) => setFieldValue({
                ...fieldValue,
                type: target.value
              })}
            />
          </Col>
          <Col span={24} md={12}>
            <CustomField
              name={event ? null : 'startDate'}
              label='Start Event'
              date
            >
              <DatePicker
                size='large'
                placeholder='Start Event'
                style={{ width: '100%' }}
                value={moment(datePicker.startDate)}
                onChange={(date, dateString) =>
                  setDatePicker({ ...datePicker, startDate: dateString }
                  )}
              />
            </CustomField>
          </Col>
          <Col span={24} md={12}>
            <CustomField
              name={event ? null : 'endDate'}
              label='End Event'
              date
            >
              <DatePicker
                size='large'
                placeholder='End Event'
                style={{ width: '100%' }}
                value={moment(datePicker.endDate)}
                onChange={(date, dateString) =>
                  setDatePicker({ ...datePicker, endDate: dateString }
                  )}
              />
            </CustomField>
          </Col>
          <Col span={24} md={24}>
            <CustomField
              name={event ? null : 'description'}
              value={event ? fieldValue?.description : null}
              label='Event Description'
              placeholder='event type'
              description
              onChange={({ target }) => setFieldValue({
                ...fieldValue,
                description: target.value
              })}
            />
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              name='organizerImage'
              label='Upload Organizes'
              rules={[!event && rules.required]}
            >
              <Upload {...props}>
                <CameraOutlined style={{ fontSize: 24 }} />
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              name='banner'
              label='Upload Banner'
              rules={[!event && rules.required]}
            >
              <Upload {...props}>
                <CameraOutlined style={{ fontSize: 24 }} />
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <CustomField
              name={event ? null : 'organizerName'}
              value={event ? fieldValue?.organizer?.name : null}
              label='Organize Name'
              placeholder='event oraganizer name'
              onChange={({ target }) => setFieldValue({
                ...fieldValue,
                organizer: {
                  ...fieldValue.organizer,
                  name: target.value
                }
              })}
            />
            <CustomField
              name={event ? null : 'publisher'}
              value={event ? fieldValue?.publisher : null}
              label='Publisher'
              placeholder='event publisher name'
              onChange={({ target }) => setFieldValue({
                ...fieldValue,
                publisher: target.value
              })}
            />
          </Col>
        </Row>
        <Row
          justify='space-between'
          gutter={16}
          className='form-footer'
        >
          <Button
            icon={<RetweetOutlined />}
            onClick={() => form.resetFields()}
          >
            Reset
          </Button>
          <Space>
            <Button
              htmlType='submit'
              type='primary'
              loading={loading}
              icon={<UploadOutlined />}
            >
              {event ? 'Update' : 'Create'} Event
            </Button>
            {event &&
              <Button onClick={onHandleStep}>
                Next
              </Button>}
          </Space>
        </Row>
      </Form>
    </Content>
  )
}

const mapStateToProps = ({ reducerEvent }) => {
  return { ...reducerEvent }
}

export default connect(mapStateToProps)(StepEvent)

StepEvent.propTypes = {
  dispatch: PropTypes.func,
  step: PropTypes.number,
  token: PropTypes.string,
  loading: PropTypes.bool,
  event: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}

CustomField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  onChange: PropTypes.func,
  date: PropTypes.bool,
  description: PropTypes.bool,
  children: PropTypes.object
}
