/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LayoutDashboard from 'src/layout/dashboard'
import { useLocation } from 'react-router-dom'

const CertificatePreview = ({ dispatch, certificateId, token }) => {
  const { state } = useLocation()

  useEffect(() => {
    const items = {
      ...state,
      body: new Blob([JSON.stringify(state.body)], {
        type: 'application/json'
      })
    }

    dispatch({
      type: 'certifactes/detail',
      payload: { token, items }
    })
  }, [])
  return (
    <LayoutDashboard>
      dadadad
    </LayoutDashboard>
  )
}

const mapStateToProps = props => {}

export default connect(mapStateToProps)(CertificatePreview)

CertificatePreview.propTypes = {
  dispatch: PropTypes.func,
  certificateId: PropTypes.string,
  token: PropTypes.string
}
