import { all, put, takeEvery } from 'redux-saga/effects'
import { actions } from '../Utils/actions'

export function * SET_VISIBLE ({ payload }) {
  yield put({
    type: 'utils/SET_STATE',
    payload: {
      visible: payload.params
    }
  })
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.SET_VISIBLE, SET_VISIBLE)
  ])
}
