/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

export const PrivateRoute = ({ children }) => {
  const auth = useAuth()
  return auth ? children : <Navigate to='/login' />
}

export const GuesRoute = ({ children }) => {
  const auth = useAuth()
  return auth ? <Navigate to='/' /> : children
}

function useAuth () {
  const isAuthorized = localStorage.getItem('isAuthorized')
  const isAuthenticated = localStorage.getItem('isAuthenticated')

  return isAuthorized && isAuthenticated
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}

GuesRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}
