import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Image, Button, Card, Layout, Row } from 'antd'
import { InfoCircleTwoTone, PlusCircleOutlined } from '@ant-design/icons'

import { CustomGap } from 'src/components'
import ILEmptyData from 'src/assets/images/illustration/empty.png'

const { Content } = Layout

const CustomEmpty = ({ type, description, subdesc }) => {
  switch (type) {
    case 'event':
      return (
        <Content className='content-empty'>
          <Image src={ILEmptyData} preview={false} />
          <p className='title'>
            Sorry, you don't have an {description || 'event'} yet
          </p>
          <p className='subtitle'>
            Want to create new {subdesc?.toLowerCase() || 'event'} ?
          </p>
          <CustomGap height='1rem' />
          <Link to='/create-event'>
            <Button type='primary' icon={<PlusCircleOutlined />}>
              Create New {subdesc || 'Event'}
            </Button>
          </Link>
          <CustomGap height='2rem' />
        </Content>
      )
    case 'create':
      return (
        <Content className='empty-item'>
          <Row justify='center'>
            <Card hoverable>
              <InfoCircleTwoTone style={{ fontSize: 28 }} />
              <p className='title'>
                Items not yet available
              </p>
              <p className='subtitle'>
                Pleast create your participant or committe
              </p>
            </Card>
          </Row>
        </Content>
      )
    default:
      return (
        <Content>
          <Row justify='center'>
            <Card className='empty-item'>
              <InfoCircleTwoTone style={{ fontSize: 28 }} />
              <p className='title'>
                Items not yet available
              </p>
              <p className='subtitle'>
                Pleast create your participant or committe
              </p>
            </Card>
          </Row>
        </Content>

      )
  }
}

export default CustomEmpty

CustomEmpty.propTypes = {
  type: PropTypes.string,
  description: PropTypes.string,
  subdesc: PropTypes.string
}
