import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Input,
  Image,
  Card,
  Menu,
  Row,
  Col,
  PageHeader,
  Button,
  Layout,
  Popover
} from 'antd'
import {
  SearchOutlined,
  PlusCircleFilled,
  MoreOutlined,
  DeleteTwoTone
} from '@ant-design/icons'

import CustomLayout from 'src/layout/dashboard'
import { CustomEmpty, CustomGap, CustomLoading } from 'src/components'

const { Content } = Layout

const CertificateMenu = ({
  list,
  loading,
  dispatch
}) => {
  const [name, setName] = useState('')
  // eslint-disable-next-line no-undef
  const token = localStorage.getItem('isAuthorized')

  useEffect(() => {
    dispatch({
      type: 'certificate/get_certificates',
      payload: { token, name }
    })
  }, [])

  const onHandleDeleteCertificate = id => {
    dispatch({
      type: 'certificate/delete_certificate',
      payload: {
        id, token
      }
    })
  }

  const popover = (item) => {
    return (
      <Menu defaultSelectedKeys={['0']}>
        <Menu.Item
          icon={<DeleteTwoTone />}
          onClick={() => onHandleDeleteCertificate(item.id)}
        >
          Delete
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <CustomLayout>
      <Content className='container container-cerfificate'>
        <PageHeader ghost={false}>
          <Row align='middle' justify='space-between'>
            <Col span={24} md={14}>
              <Input
                size='large'
                placeholder='search event by session name, moderator name, or event name'
                prefix={<SearchOutlined />}
                onChange={({ target }) => setName(target.value)}
              />
            </Col>
            <Link to='/design-certificate'>
              <Button type='primary' icon={<PlusCircleFilled />}>
                Design Certificated
              </Button>
            </Link>
          </Row>
        </PageHeader>
        <CustomGap height='1rem' />
        <Row className='content-card'>
          {list?.items?.map((item, key) =>
            <Col key={key} span={24}>
              <Card hoverable>
                <Row justify='space-between' align='middle'>
                  <Image src={item.image} />
                  <CustomGap width='2rem' />
                  <Content>
                    <p className='title'>
                      {item.name}
                    </p>
                    <CustomGap height='10px' />
                    <Row>
                      <Button className='description'>
                        {item.orientation}
                      </Button>
                      <Button className='format'>
                        {item.format}
                      </Button>
                      <Button className='type'>
                        {item.category}
                      </Button>
                    </Row>
                  </Content>
                  <Popover
                    overlayClassName='popover-content'
                    placement='leftTop'
                    trigger='click'
                    content={popover(item)}
                  >
                    <Button>
                      <MoreOutlined />
                    </Button>
                  </Popover>
                </Row>
              </Card>
              <CustomGap height='.5rem' />
            </Col>
          )}
          {list?.items?.length <= 0 &&
            <CustomEmpty
              type='event'
              description='design certificates'
              subdesc='Design'
            />}
        </Row>
      </Content>
      {loading && <CustomLoading key='loading' />}
    </CustomLayout>
  )
}

const mapStateProps = ({ reducerCertificate }) => {
  return { ...reducerCertificate }
}

export default connect(mapStateProps)(CertificateMenu)

CertificateMenu.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  list: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}
